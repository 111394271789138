import React, { useState, useEffect } from 'react'
import translate from '../../../i18n/translate'
import { sendUserRating } from '../../../services/auxiliaryServices'
import { ReactComponent as StarIcon } from '../../../assets/icons/yellow_star.svg'
import whiteCloseIcon from '../../../assets/icons/x_white_icon.svg'
import purpleCloseIcon from '../../../assets/icons/purpleCloseIconFeedbackReport.svg'
import './feedbackReport.scss'
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer'

const FeedbackReport = ({
  onClose,
  onSubmitSuccess,
  onSubmitError,
  feedbackToast,
  setFeedbackToast
}) => {
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 520)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const isButtonActive =
    rating > 0 &&
    feedback
      .trim()
      .split(/\s+/)
      .filter((word) => word.length >= 2).length >= 1

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating)
  }

  const handleSubmit = async () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    try {
      const ratingResponse = await sendUserRating({ rating, feedback })

      if (ratingResponse.error) {
        onSubmitError()
      } else {
        onSubmitSuccess()
        setRating(0)
        setFeedback('')
        setIsSubmitted(true)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const preventKeyboardNavigation = (e) => {
      if (e.key.startsWith('Arrow') || e.key === 'Tab') {
        e.preventDefault()
      }
    }

    window.addEventListener('keydown', preventKeyboardNavigation)

    return () => {
      window.removeEventListener('keydown', preventKeyboardNavigation)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClose = () => {
    onClose()
    setFeedbackToast(null)
  }

  return (
    <>
      {!isMobile && <div className="feedback-report__backdrop"></div>}
      <div className="feedback-report">
        <button
          className="feedback-report__close-button"
          onClick={handleClose}
          aria-label="Cerrar"
        >
          <img
            src={isMobile ? purpleCloseIcon : whiteCloseIcon}
            alt="Close feedback"
          />
        </button>
        <div className="feedback-report__title">
          {translate('feedback-report__title')}
        </div>
        <div className="feedback-report__container">
          <div className="feedback-report__description">
            {translate('feedback-report__description')}
          </div>

          <div className="feedback-report__rating">
            {[5, 4, 3, 2, 1].map((star) => (
              <span
                key={star}
                className="feedback-report__star"
                onClick={() => handleStarClick(star)}
              >
                <StarIcon
                  className={`feedback-report__star-icon ${
                    rating >= star ? 'active' : ''
                  }`}
                />
              </span>
            ))}
          </div>

          <div className="feedback-report__comment-description">
            {translate('feedback-report__comment-description')}
          </div>
        </div>
        <textarea
          className="feedback-report__comment-input"
          placeholder={translate('feedback-report__comment-placeholder')}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          disabled={isSubmitted}
          maxLength={600}
        />

        <button
          className={`feedback-report__submit-button ${
            isButtonActive ? 'feedback-report__submit-button--active' : ''
          }`}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {translate('feedback-report__submit')}
        </button>
        {feedbackToast && window.innerWidth <= 520 && (
          <NotificationDrawer
            data={feedbackToast}
            onClose={() => setFeedbackToast(null)}
          />
        )}
      </div>
    </>
  )
}

export default FeedbackReport
