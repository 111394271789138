import React, { useEffect } from 'react'

import useMetaTabAvatar from './useMetaTabAvatar'
import PopupSelector from '../PopupSelector/PopupSelector'
import AvatarSlider from './components/AvatarSlider/AvatarSlider'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../services/analysisService'
import AvatarData from './components/AvatarData/AvatarData'
import translate from '../../../i18n/translate'
import ItemDetail from '../MetaTabCommons/ItemDetail/ItemDetail'
import { setAvatarsNotification } from '../../../services/avatarService'
import { getEffectsUserConfig } from '../../../services/settingsService'

const MetaTabAvatar = ({ isIntro, handleIntroContinue, userExperience }) => {
  const {
    handleSelectAvatar,
    avatarsData,
    avatarSelected,
    isLoading,
    showToast,
    setShowToast,
    setDataAvatarPending,
    avatarPending,
    startSlide,
    avatarsTotal
  } = useMetaTabAvatar()

  const { renderSoundFXController, playSoundEffectWithConfig } = useSound()

  const typePopupSelector = {
    AVATAR: 'avatar',
    PETS: 'pet'
  }

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Avatar Selector Page Viewed']
    )

    setAvatarsNotification(false)
  }, [])

  const handleAvatarClick = (avatar) => {
    playSoundEffectWithConfig('controlPanel')
    handleSelectAvatar(avatar)
  }

  const handleAvatarSelect = () => {
    playSoundEffectWithConfig('controlPanel')
    handleSelectAvatar(avatarPending)
    handleIntroContinue()
  }

  const contentAvatar = (
    <>
      <AvatarSlider
        avatars={avatarsData}
        avatarSelected={avatarSelected}
        playSoundEffect={playSoundEffectWithConfig}
        handleSelectAvatar={handleAvatarClick}
        setDataAvatarPending={setDataAvatarPending}
        startSlide={startSlide}
        isIntro={isIntro}
        cardButtonText={
          avatarSelected.single_image === avatarPending.single_image
            ? translate('avatar_selected')
            : translate('avatar_select')
        }
        isSelected={avatarSelected.single_image === avatarPending.single_image}
        handleMainButton={handleAvatarSelect}
      />
      {isIntro && (
        <AvatarData
          title={avatarPending.name}
          description={avatarPending.description}
          showMainButton={isIntro}
          isIntro={isIntro}
          textMainButton={translate('avatar_select')}
          handleMainButton={handleAvatarSelect}
        />
      )}
      {!isIntro && avatarsTotal && (
        <div className="metatabavatar-container-total">
          {avatarsTotal?.map((avatarTotal, index) => (
            <div
              className="metatabavatar-container-total__item"
              key={avatarTotal.guid}
            >
              <ItemDetail
                isSelected={
                  avatarSelected.single_image === avatarTotal.single_image
                }
                avatar={avatarTotal}
                onClickSelect={() => {
                  if (getEffectsUserConfig() === 1) {
                    playSoundEffectWithConfig('avatarSelected')
                  }
                  handleAvatarClick(avatarTotal)
                }}
                isIntro={false}
                isDisabled={avatarTotal.level > userExperience?.level}
                level={avatarTotal.level}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )

  return (
    <div className="meta-popup-selector">
      {renderSoundFXController()}
      <PopupSelector
        isIntro={isIntro}
        isLoading={isLoading}
        showToast={showToast}
        setShowToast={setShowToast}
        content={contentAvatar}
        type={typePopupSelector.AVATAR}
        typePopupSelector={typePopupSelector}
        level={userExperience?.level}
      />
    </div>
  )
}

export default MetaTabAvatar
