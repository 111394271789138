import React, { useEffect, useState } from 'react'
import { ReactComponent as SvgOk } from '../../../assets/icons/ok.svg'
import { ReactComponent as SvgNotOk } from '../../../assets/icons/notOk.svg'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import Drawer from '../../../../../modules/atoms/Drawer/Drawer'
import './toast.scss'

const Toast = ({
  closeToast,
  data,
  showCloseButton = false,
  onClose,
  autoClose = true
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (autoClose) {
      const closeToastTimer = setTimeout(() => {
        closeToast()
      }, 3000)

      return () => clearTimeout(closeToastTimer)
    }
  }, [autoClose, closeToast, isMobile])

  const handleClose = () => {
    setIsVisible(false)
    if (onClose) onClose()
    closeToast()
  }

  if (!isVisible) return null

  const toastContent = (
    <div className="toast-blueberry_frame">
      {showCloseButton && (
        <button
          className="toast-blueberry__close-button"
          onClick={handleClose}
          aria-label="Cerrar"
        >
          <img src="/assets/graphics/icons/x_white_icon.svg" alt="Cerrar" />
        </button>
      )}
      {data.type === 'success' ? (
        <SvgOk className="toast-blueberry_icon" />
      ) : (
        <SvgNotOk className="toast-blueberry_icon" />
      )}
      <div className="toast-blueberry_title">{data.title}</div>
      <div className="toast-blueberry_message">{data.message}</div>
    </div>
  )

  if (isMobile) {
    return (
      <Drawer direction="bottom" onClose={handleClose} showCloseButton={false}>
        {toastContent}
      </Drawer>
    )
  }

  return (
    <div className="toast-blueberry" onClick={closeToast}>
      {toastContent}
    </div>
  )
}

export default Toast
