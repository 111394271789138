import React, { useRef, useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { useTranslation } from 'react-i18next'
import { observer, useLemonadeQuestion } from '@oneclick/react-lemonade-editor'
import '@oneclick/react-lemonade-editor/dist/index.css'
import { ReactComponent as SvgScrollMark } from '../../assets/icons/scroll-mark.svg'
import ExerciseAudioButton from '../../components/atoms/ExerciseAudioButton/ExerciseAudioButton'
import FeedbackBox from '../../components/atoms/FeedbackBox/FeedbackBox'
import FeedbackPanelQuiz from '../../components/atoms/FeedbackPanelQuiz/FeedbackPanelQuiz'
import ActivityFooter from '../../components/molecules/ActivityFooter/ActivityFooter'
import DummyActivity from '../../components/molecules/DummyActivity/DummyActivity'
import ScaffoldContainer from '../../components/molecules/ScaffoldContainer/ScaffoldContainer'
import { ACTIVITY_TYPES } from '../../constants/ActivityConstants'
import { FEEDBACK_TYPE } from '../../constants/FeedbackConstants'
import { useActivityContext } from '../../context/ActivityContext/useActivityContext'
import { useActivityManager } from '../../hooks/useActivityManager'
import { getDeviceType, isIosDevice } from '../../utils/devices'
import './activityView.scss'
import { useActivityView } from './useActivityView'
import ScaffoldMobileContainer from '../../components/molecules/ScaffoldMobileContainer/ScaffoldMobileContainer'

function ActivityView({
  showSolutionQuestion,
  onActivityEvent,
  activityInformation,
  isHideFooter,
  studentResponse,
  showAllFeedback = true,
  finishedTimeQuestion,
  hasCheckExerciseAPiResponded,
  requiresAudioAssistance,
  hasPreviowsPopups,
  getStimulusAudio,
  getTextAudio,
  numberQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine,
  multiplicationSymbol,
  exerciseLanguage,
  pathAssetsSchool,
  practiceBaseData
}) {
  const { t } = useTranslation()

  const {
    activityType,
    activity: currentActivity,
    activityStore,
    isAnswerCorrect,
    isScaffold,
    scaffoldCurrentQuestion,
    isDemoMode,
    setActivityStore,
    initializeActivity,
    isDisabledActivity
  } = useActivityContext()

  const {
    exercise: { Question, Feedback, questionApi: exerciseStore },
    updateExercise
  } = useLemonadeQuestion({
    data: currentActivity,
    lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
  })

  const [isIphone, setIsIphone] = useState(false)

  const activityRef = useRef(null)
  const activityContentRef = useRef(null)

  const {
    feedbackBoxContent,
    feedbackIndicatorObject,
    isFeedbackVisible,
    setIsFeedbackVisible,
    showScaffoldFirstStep,
    scaffoldNext,
    checkAnswer,
    resetActivity,
    handleUserChange,
    setIsResponseSend,
    isResponseSend,
    feedbackIcon,
    isDummyAnswer
  } = useActivityManager({
    onActivityEvent,
    studentResponse,
    finishedTimeQuestion,
    activityType,
    showSolutionQuestion,
    showSolutionQuestionQuizPreview,
    mathRenderEngine,
    multiplicationSymbol,
    hasCheckExerciseAPiResponded
  })

  const {
    showActivityFooter,
    activityWidth,
    footerHeight,
    isTopScrollShadowActive,
    isScrollMarkActive,
    isBottomScrollShadowActive,
    pathwayCellSize,
    toogleFeedback,
    scrollContentAndHideScrollMark,
    onKeyboardVisible,
    isScaffoldMobileVersion
  } = useActivityView({
    activityRef,
    activityType,
    currentActivity,
    activityContentRef,
    isFeedbackVisible,
    activityStore,
    exerciseStore,
    isScaffold,
    scaffoldCurrentQuestion,
    showAllFeedback,
    onActivityEvent,
    setActivityStore,
    initializeActivity,
    setIsFeedbackVisible,
    updateExercise,
    isResponseSend,
    exerciseLanguage,
    practiceBaseData
  })

  // Para colocar el audio button al inicio del primer hijo del elemento stimulus
  const [stimulusElementFirstChild, setStimulusElementFirstChild] =
    useState(null)

  useEffect(() => {
    const observer = new MutationObserver((mutations, obs) => {
      const targetElement = document.querySelector(
        '.lemo-question-header .lemo-stimulus p'
      )
      if (
        targetElement &&
        !targetElement.querySelector('.activity-view__stimulus-audio-button')
      ) {
        if (!targetElement.firstChild) {
          targetElement.appendChild(document.createTextNode(''))
        }
        setStimulusElementFirstChild(targetElement)
        obs.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    return () => observer.disconnect()
  }, [currentActivity])

  useEffect(() => {
    let audioButtonReactRoot = null
    let tempContainer = null

    if (stimulusElementFirstChild && activityType !== ACTIVITY_TYPES.QUIZ) {
      // Creamos el contenedor fuera del if para poder limpiarlo en el cleanup
      tempContainer = document.createElement('div')
      tempContainer.className = 'activity-view__stimulus-audio-button'

      // Verificamos que el elemento padre aún existe antes de insertar
      if (stimulusElementFirstChild.isConnected) {
        stimulusElementFirstChild.insertBefore(
          tempContainer,
          stimulusElementFirstChild.firstChild
        )

        audioButtonReactRoot = createRoot(tempContainer)
        audioButtonReactRoot.render(
          <ExerciseAudioButton
            requiresAudioAssistance={requiresAudioAssistance}
            eventAudioType="activity_stimulus"
            hasPreviowsPopups={hasPreviowsPopups}
            getAudio={getStimulusAudio}
            onActivityEvent={onActivityEvent}
            exerciseGuid={activityInformation?.exerciseGuid}
          />
        )
      }
    }

    return () => {
      // Aseguramos que el desmontaje sea seguro
      if (audioButtonReactRoot) {
        try {
          // Envolvemos en un setTimeout para evitar el desmontaje síncrono
          setTimeout(() => {
            audioButtonReactRoot.unmount()
            tempContainer?.remove()
          }, 0)
        } catch (error) {
          console.warn('Error al desmontar audioButtonReactRoot:', error)
        }
      }
    }
  }, [stimulusElementFirstChild, activityType, hasPreviowsPopups])
  // fin del codigo para colocar el audio button al inicio del primer hijo del elemento stimulus

  const hasDummyEnabled = () => {
    const hasCurrentActivity = !(
      Object.keys(currentActivity).length === 0 &&
      currentActivity.constructor === Object
    )
    return (isDemoMode === 1 || isDemoMode === true) && hasCurrentActivity
  }

  const shouldShowFeedbackBox = () => {
    const isFeedbackNecessary =
      feedbackBoxContent &&
      (feedbackBoxContent.title || feedbackBoxContent.message)

    return (
      isFeedbackNecessary &&
      activityStore?.isEvaluated &&
      isFeedbackVisible &&
      showAllFeedback &&
      activityType !== ACTIVITY_TYPES.QUIZ
    )
  }

  const checkShowBottomScroll = () => {
    if (activityType !== ACTIVITY_TYPES.QUIZ) {
      return isBottomScrollShadowActive
    } else {
      return (
        isBottomScrollShadowActive &&
        showActivityFooter &&
        !isHideFooter &&
        !isResponseSend
      )
    }
  }

  const getIphoneSpacingClass = () => {
    if (!isIphone) return ''

    // Usar visualViewport para una detección más precisa
    const viewportHeight = window.visualViewport?.height || window.innerHeight
    const windowHeight = window.innerHeight
    const hasToolbar = Math.abs(viewportHeight - windowHeight) > 20

    return hasToolbar
      ? ' activity-view_iphone'
      : 'activity-view_iphone-no-space'
  }

  useEffect(() => {
    const isIos = isIosDevice()
    const deviceType = getDeviceType()

    setIsIphone(isIos && deviceType === 'phone')

    const checkToolbar = () => {
      if (isIos && deviceType === 'phone') {
        getIphoneSpacingClass()
      }
    }

    checkToolbar()
    window.addEventListener('resize', checkToolbar)

    return () => window.removeEventListener('resize', checkToolbar)
  }, [])

  return (
    <div
      className={'activity-view notranslate' + getIphoneSpacingClass()}
      ref={activityRef}
    >
      {isTopScrollShadowActive && activityType !== ACTIVITY_TYPES.QUIZ && (
        <div className="activity-view_top-scroll-shadow"></div>
      )}

      <div
        className={
          'activity-view_content' +
          (isScaffold && isScaffoldMobileVersion
            ? ' scaffold-mobile-visible'
            : '')
        }
        ref={activityContentRef}
      >
        {hasDummyEnabled() && (
          <DummyActivity
            data={activityInformation}
            sendResponse={checkAnswer}
            thumbsDisabled={
              activityStore?.isEvaluated &&
              (!isScaffold || !scaffoldCurrentQuestion?.isMain)
            }
          />
        )}

        <div
          className={
            activityType !== ACTIVITY_TYPES.QUIZ
              ? 'activity-view_content__with-audio-button'
              : 'activity-view_content__without-audio-button'
          }
        >
          {activityType === ACTIVITY_TYPES.QUIZ && numberQuestion && (
            <div className="number-question">
              <span>{numberQuestion}</span>
            </div>
          )}
          <div>
            {/* Ejercicio SIN Scaffold */}
            {!isScaffold && (
              <>
                <Question
                  changed={handleUserChange}
                  onKeyboardVisible={onKeyboardVisible}
                  autoFocus={isDisabledActivity ? 'disabled' : ''}
                  disabled={isDisabledActivity}
                  initialCellSize={pathwayCellSize}
                  pathwayAutoSizeMediaQuery={1}
                />
                {activityType !== ACTIVITY_TYPES.QUIZ && <Feedback />}
              </>
            )}

            {/* Ejercicio CON Scaffold */}
            {isScaffold &&
              (!isScaffoldMobileVersion ? (
                <ScaffoldContainer
                  currentExercise={currentActivity}
                  handleUserChange={handleUserChange}
                  autoFocus={isDisabledActivity ? 'disabled' : ''}
                  disabled={isDisabledActivity}
                ></ScaffoldContainer>
              ) : (
                <ScaffoldMobileContainer
                  currentExercise={currentActivity}
                  checkAnswer={checkAnswer}
                  hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
                  activityWidth={activityWidth}
                  autoFocus={isDisabledActivity ? 'disabled' : ''}
                  disabled={isDisabledActivity}
                  onActivityEvent={onActivityEvent}
                  resetActivity={resetActivity}
                  feedbackIndicatorObject={feedbackIndicatorObject}
                  pathAssetsSchool={pathAssetsSchool}
                  // Para el feedback de la actividad
                  feedbackBoxContent={feedbackBoxContent}
                  exerciseGuid={activityInformation?.exerciseGuid}
                  requiresAudioAssistance={requiresAudioAssistance}
                  getTextAudio={getTextAudio}
                  mathRenderEngine={mathRenderEngine}
                  // Para respuestas por defecto (dummy)
                  isDummyAnswer={isDummyAnswer}
                />
              ))}
          </div>
        </div>

        <div>
          {/* Pista */}
          {!isScaffold &&
            activityStore?.hints?.isVisible &&
            showAllFeedback && (
              <FeedbackBox
                feedbackType={FEEDBACK_TYPE.HINT}
                content={{ message: activityStore.hints.currentHint }}
                onActivityEvent={onActivityEvent}
                panelInformationType={'activity_hint'}
                exerciseGuid={activityInformation?.exerciseGuid}
                requiresAudioAssistance={requiresAudioAssistance}
                getTextAudio={getTextAudio}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              />
            )}

          {/* Feedback respuesta */}
          {(!isScaffold || !isScaffoldMobileVersion) &&
            shouldShowFeedbackBox() && (
              <FeedbackBox
                feedbackType={
                  isAnswerCorrect
                    ? FEEDBACK_TYPE.CORRECT
                    : FEEDBACK_TYPE.INCORRECT
                }
                content={feedbackBoxContent}
                hideTitle={!isAnswerCorrect}
                onActivityEvent={onActivityEvent}
                panelInformationType={'activity_feedback'}
                exerciseGuid={activityInformation?.exerciseGuid}
                requiresAudioAssistance={requiresAudioAssistance}
                getTextAudio={getTextAudio}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              />
            )}
          {activityType === ACTIVITY_TYPES.QUIZ &&
            showSolutionQuestion &&
            showAllFeedback && (
              <FeedbackPanelQuiz
                content={feedbackBoxContent}
                iconType={feedbackIcon || ''}
                onShowLess={null}
                onClose={isAnswerCorrect ? null : toogleFeedback}
                setIsFeedbackReady={true}
                isVisible={true}
                panelInformationType={'activity_feedback'}
                translate={t}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              ></FeedbackPanelQuiz>
            )}
          {activityType === ACTIVITY_TYPES.QUIZ &&
            isResponseSend &&
            !showSolutionQuestion && (
              <FeedbackPanelQuiz
                content={{ title: t('app_practice_send_answer') }}
                iconType={'quiz_send_answer'}
                onShowLess={null}
                onClose={isAnswerCorrect ? null : toogleFeedback}
                setIsFeedbackReady={true}
                isVisible={true}
                panelInformationType={'activity_feedback'}
                translate={t}
                mathRenderEngine={mathRenderEngine}
                pathAssetsSchool={pathAssetsSchool}
              ></FeedbackPanelQuiz>
            )}
        </div>

        {isScrollMarkActive && (
          <div
            className="activity-view__scroll-mark"
            style={{ bottom: footerHeight + 'px' }}
          >
            <SvgScrollMark onClick={scrollContentAndHideScrollMark} />
          </div>
        )}
      </div>
      {checkShowBottomScroll() && (
        <div className="activity-view_bottom-scroll-shadow"></div>
      )}

      {!isHideFooter &&
        showActivityFooter &&
        (!isScaffold || !isScaffoldMobileVersion) &&
        ((!isResponseSend && activityType === ACTIVITY_TYPES.QUIZ) ||
          activityType !== ACTIVITY_TYPES.QUIZ) && (
          <ActivityFooter
            checkAnswer={
              activityType !== ACTIVITY_TYPES.QUIZ
                ? checkAnswer
                : () => setIsResponseSend(true)
            }
            showScaffoldFirstStep={showScaffoldFirstStep}
            feedbackIndicatorObject={feedbackIndicatorObject}
            setIsFeedbackVisible={setIsFeedbackVisible}
            resetActivity={resetActivity}
            onActivityEvent={onActivityEvent}
            isResponseSend={isResponseSend}
            activityWidth={activityWidth}
            hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
            scaffoldNext={scaffoldNext}
            pathAssetsSchool={pathAssetsSchool}
          />
        )}
    </div>
  )
}

export default observer(ActivityView)
