import uuidv4 from './uuidv4'

import SILENCE from '../assets/audios/silence.mp3'
import HINT_ES from '../assets/audios/es/hint.mp3'
import HINT_EN from '../assets/audios/en/hint.mp3'
import HINT_PT from '../assets/audios/pt/hint.mp3'
import FEEDBACK_CORRECT_ES from '../assets/audios/es/feedback_correct.mp3'
import FEEDBACK_CORRECT_EN from '../assets/audios/en/feedback_correct.mp3'
import FEEDBACK_CORRECT_PT from '../assets/audios/pt/feedback_correct.mp3'
import FEEDBACK_INCORRECT_ES from '../assets/audios/es/feedback_incorrect.mp3'
import FEEDBACK_INCORRECT_EN from '../assets/audios/en/feedback_incorrect.mp3'
import FEEDBACK_INCORRECT_PT from '../assets/audios/pt/feedback_incorrect.mp3'
import FEEDBACK_HINT_CORRECT_ES from '../assets/audios/es/feedback_hint_correct.mp3'
import FEEDBACK_HINT_CORRECT_EN from '../assets/audios/en/feedback_hint_correct.mp3'
import FEEDBACK_HINT_CORRECT_PT from '../assets/audios/pt/feedback_hint_correct.mp3'
import FEEDBACK_HINT_INCORRECT_ES from '../assets/audios/es/feedback_hint_incorrect.mp3'
import FEEDBACK_HINT_INCORRECT_EN from '../assets/audios/en/feedback_hint_incorrect.mp3'
import FEEDBACK_HINT_INCORRECT_PT from '../assets/audios/pt/feedback_hint_incorrect.mp3'
import FEEDBACK_ANSWER_SUBMITTED_AUDIO_EN from '../assets/audios/en/feedback_answer-submitted_audio.mp3'
import FEEDBACK_ANSWER_SUBMITTED_AUDIO_ES from '../assets/audios/es/feedback_answer-submitted_audio.mp3'
import FEEDBACK_ANSWER_SUBMITTED_AUDIO_PT from '../assets/audios/pt/feedback_answer-submitted_audio.mp3'
import FEEDBACK_FANTASTIC_AUDIO_EN from '../assets/audios/en/feedback_fantastic_audio.mp3'
import FEEDBACK_FANTASTIC_AUDIO_ES from '../assets/audios/es/feedback_fantastic_audio.mp3'
import FEEDBACK_FANTASTIC_AUDIO_PT from '../assets/audios/pt/feedback_fantastic_audio.mp3'
import FEEDBACK_KEEP_TRYING_AUDIO_EN from '../assets/audios/en/feedback_keep-trying_audio.mp3'
import FEEDBACK_KEEP_TRYING_AUDIO_ES from '../assets/audios/es/feedback_keep-trying_audio.mp3'
import FEEDBACK_KEEP_TRYING_AUDIO_PT from '../assets/audios/pt/feedback_keep-trying_audio.mp3'
import FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_EN from '../assets/audios/en/feedback_keep-up-the-effort_audio.mp3'
import FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_ES from '../assets/audios/es/feedback_keep-up-the-effort_audio.mp3'
import FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_PT from '../assets/audios/pt/feedback_keep-up-the-effort_audio.mp3'
import FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_EN from '../assets/audios/en/feedback_oh-no-You-did-not-have-time-to-answer_audio.mp3'
import FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_ES from '../assets/audios/es/feedback_oh-no-You-did-not-have-time-to-answer_audio.mp3'
import FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_PT from '../assets/audios/pt/feedback_oh-no-You-did-not-have-time-to-answer_audio.mp3'
import FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_EN from '../assets/audios/en/feedback_think-about-why-you-have-failed_audio.mp3'
import FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_ES from '../assets/audios/es/feedback_think-about-why-you-have-failed_audio.mp3'
import FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_PT from '../assets/audios/pt/feedback_think-about-why-you-have-failed_audio.mp3'
import FEEDBACK_VERY_GOOD_WORK_AUDIO_EN from '../assets/audios/en/feedback_very-good-work_audio.mp3'
import FEEDBACK_VERY_GOOD_WORK_AUDIO_ES from '../assets/audios/es/feedback_very-good-work_audio.mp3'
import FEEDBACK_VERY_GOOD_WORK_AUDIO_PT from '../assets/audios/pt/feedback_very-good-work_audio.mp3'
import FEEDBACK_WELL_DONE_AUDIO_EN from '../assets/audios/en/feedback_well-done_audio.mp3'
import FEEDBACK_WELL_DONE_AUDIO_ES from '../assets/audios/es/feedback_well-done_audio.mp3'
import FEEDBACK_WELL_DONE_AUDIO_PT from '../assets/audios/pt/feedback_well-done_audio.mp3'

export const AUDIO_TYPE = {
  SILENCE: 'SILENCE',
  HINT: 'HINT',
  FEEDBACK_CORRECT: 'FEEDBACK_CORRECT',
  FEEDBACK_INCORRECT: 'FEEDBACK_INCORRECT',
  FEEDBACK_HINT_CORRECT: 'FEEDBACK_HINT_CORRECT',
  FEEDBACK_HINT_INCORRECT: 'FEEDBACK_HINT_INCORRECT',
  FEEDBACK_ANSWER_SUBMITTED: 'FEEDBACK_ANSWER_SUBMITTED',
  FEEDBACK_FANTASTIC: 'FEEDBACK_FANTASTIC',
  FEEDBACK_KEEP_TRYING: 'FEEDBACK_KEEP_TRYING',
  FEEDBACK_KEEP_UP_THE_EFFORT: 'FEEDBACK_KEEP_UP_THE_EFFORT',
  FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER:
    'FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER',
  FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED:
    'FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED',
  FEEDBACK_VERY_GOOD_WORK: 'FEEDBACK_VERY_GOOD_WORK',
  FEEDBACK_WELL_DONE: 'FEEDBACK_WELL_DONE'
}

const BLUEBERRO_AUDIOS = {}

// CORRECTO            -> NO se debe leer el mensaje (no se ve)
// INCORRECTO          -> SI se debe leer el mensaje
// CORRECTO CON PISTA  -> NO se debe leer el mensaje (no se ve)
// ICORRECTO CON PISTA -> SI se debe leer el mensaje
const isFeedbackSilence = (audioType) => {
  if (
    audioType === AUDIO_TYPE.FEEDBACK_CORRECT ||
    audioType === AUDIO_TYPE.FEEDBACK_HINT_CORRECT
  ) {
    return true
  }

  return false
}

export const ExerciseAudioManager = {
  store: {},

  initialize: (language) => {
    ExerciseAudioManager.stopAllAudios(true)

    BLUEBERRO_AUDIOS[AUDIO_TYPE.SILENCE] = new Audio(SILENCE)

    switch (language) {
      case 'es':
      case 'ES': {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_ES)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_ANSWER_SUBMITTED] = new Audio(
          FEEDBACK_ANSWER_SUBMITTED_AUDIO_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_FANTASTIC] = new Audio(
          FEEDBACK_FANTASTIC_AUDIO_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_TRYING] = new Audio(
          FEEDBACK_KEEP_TRYING_AUDIO_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_UP_THE_EFFORT] = new Audio(
          FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_ES
        )
        BLUEBERRO_AUDIOS[
          AUDIO_TYPE.FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER
        ] = new Audio(FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_ES)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED] =
          new Audio(FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_ES)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_VERY_GOOD_WORK] = new Audio(
          FEEDBACK_VERY_GOOD_WORK_AUDIO_ES
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_WELL_DONE] = new Audio(
          FEEDBACK_WELL_DONE_AUDIO_ES
        )
        break
      }

      case 'pt':
      case 'PT': {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_PT)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_ANSWER_SUBMITTED] = new Audio(
          FEEDBACK_ANSWER_SUBMITTED_AUDIO_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_FANTASTIC] = new Audio(
          FEEDBACK_FANTASTIC_AUDIO_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_TRYING] = new Audio(
          FEEDBACK_KEEP_TRYING_AUDIO_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_UP_THE_EFFORT] = new Audio(
          FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_PT
        )
        BLUEBERRO_AUDIOS[
          AUDIO_TYPE.FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER
        ] = new Audio(FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_PT)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED] =
          new Audio(FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_PT)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_VERY_GOOD_WORK] = new Audio(
          FEEDBACK_VERY_GOOD_WORK_AUDIO_PT
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_WELL_DONE] = new Audio(
          FEEDBACK_WELL_DONE_AUDIO_PT
        )
        break
      }

      default: {
        BLUEBERRO_AUDIOS[AUDIO_TYPE.HINT] = new Audio(HINT_EN)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_CORRECT] = new Audio(
          FEEDBACK_CORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_INCORRECT] = new Audio(
          FEEDBACK_INCORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_CORRECT] = new Audio(
          FEEDBACK_HINT_CORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_HINT_INCORRECT] = new Audio(
          FEEDBACK_HINT_INCORRECT_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_ANSWER_SUBMITTED] = new Audio(
          FEEDBACK_ANSWER_SUBMITTED_AUDIO_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_FANTASTIC] = new Audio(
          FEEDBACK_FANTASTIC_AUDIO_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_TRYING] = new Audio(
          FEEDBACK_KEEP_TRYING_AUDIO_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_KEEP_UP_THE_EFFORT] = new Audio(
          FEEDBACK_KEEP_UP_THE_EFFORT_AUDIO_EN
        )
        BLUEBERRO_AUDIOS[
          AUDIO_TYPE.FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER
        ] = new Audio(FEEDBACK_OH_NO_YOU_DID_NOT_HAVE_TIME_TO_ANSWER_AUDIO_EN)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED] =
          new Audio(FEEDBACK_THINK_ABOUT_WHY_YOU_HAVE_FAILED_AUDIO_EN)
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_VERY_GOOD_WORK] = new Audio(
          FEEDBACK_VERY_GOOD_WORK_AUDIO_EN
        )
        BLUEBERRO_AUDIOS[AUDIO_TYPE.FEEDBACK_WELL_DONE] = new Audio(
          FEEDBACK_WELL_DONE_AUDIO_EN
        )
      }
    }
  },

  addAudio: (audioData) => {
    const id = uuidv4()

    // Si recibimos un objeto con type, usamos el audio correspondiente de BLUEBERRO_AUDIOS
    if (audioData.type) {
      ExerciseAudioManager.store[id] = {
        audio:
          BLUEBERRO_AUDIOS[audioData.type] ||
          BLUEBERRO_AUDIOS[AUDIO_TYPE.SILENCE]
      }
    } else {
      // Para mantener compatibilidad con el código existente
      ExerciseAudioManager.store[id] = { audio: audioData }
    }

    return id
  },

  isPlaying: (audio) => {
    return (
      audio.currentTime > 0 &&
      !audio.paused &&
      !audio.ended &&
      audio.readyState > audio.HAVE_CURRENT_DATA
    )
  },

  playAudio: async (id, previousAudioType) => {
    // Se paran los que estén sonando
    ExerciseAudioManager.stopAllAudios()

    // Seleccionar audio
    const auxAudio = !isFeedbackSilence(previousAudioType)
      ? ExerciseAudioManager.store[id].audio
      : BLUEBERRO_AUDIOS[AUDIO_TYPE.SILENCE]

    if (previousAudioType) {
      // Con sonido previo
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      previousAudio.onended = async () => {
        if (!ExerciseAudioManager.isPlaying(auxAudio)) {
          try {
            await auxAudio.play()
          } catch (ae) {}
        }

        previousAudio.onended = null
      }

      if (!ExerciseAudioManager.isPlaying(previousAudio)) {
        try {
          await previousAudio.play()
        } catch (ae) {}
      }
    } else {
      // Sin sonido previo
      if (!ExerciseAudioManager.isPlaying(auxAudio)) {
        try {
          await auxAudio.play()
        } catch (ae) {}
      }
    }
  },

  stopAudio: (id, previousAudioType) => {
    // Si tiene previo
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        previousAudio.onended = null

        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    // El audio principal
    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio

      if (!auxAudio.paused) {
        auxAudio.pause()
        auxAudio.currentTime = 0
      }
    }
  },

  stopAllAudios: (removeToo) => {
    for (const key in ExerciseAudioManager.store) {
      const auxAudio = ExerciseAudioManager.store[key].audio

      if (!auxAudio.paused) {
        auxAudio.onended = null

        auxAudio.pause()
        auxAudio.currentTime = 0

        if (removeToo) {
          delete ExerciseAudioManager.store[key]
        }
      }
    }

    for (const key in BLUEBERRO_AUDIOS) {
      const auxAudio = BLUEBERRO_AUDIOS[key]

      if (!auxAudio.paused) {
        auxAudio.onended = null

        auxAudio.pause()
        auxAudio.currentTime = 0
      }
    }
  },

  toogleAudio: (id, previousAudioType) => {
    let playing = false
    let hasToPlay = true

    // Si tiene sonido previo
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        hasToPlay = false

        previousAudio.onended = null
        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    // Sonido principal
    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio
      if (!auxAudio.paused) {
        hasToPlay = false

        auxAudio.pause()
        auxAudio.currentTime = 0
      }

      if (hasToPlay) {
        playing = true
        ExerciseAudioManager.playAudio(id, previousAudioType)
      }
    }

    return playing
  },

  removeAudio: (id, previousAudioType) => {
    if (previousAudioType) {
      const previousAudio = BLUEBERRO_AUDIOS[previousAudioType]

      if (!previousAudio.paused) {
        previousAudio.onended = null
        previousAudio.pause()
        previousAudio.currentTime = 0
      }
    }

    if (ExerciseAudioManager.store[id]) {
      const auxAudio = ExerciseAudioManager.store[id].audio
      if (!auxAudio.paused) {
        auxAudio.onended = null
        auxAudio.pause()
      }

      delete ExerciseAudioManager.store[id]
    }
  }
}

// ExerciseAudioManager.initialize()
