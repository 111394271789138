import React, { useEffect, useState } from 'react'
import { ReactComponent as IconSvg } from '../../../assets/icons/audio.svg'
import './exerciseAudioButton.scss'
// import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'
import { ExerciseAudioManager } from '../../../utils/exerciseAudioManager'
// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

const AUDIO_LOAD_STATUS = {
  UNLOADED: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3
}

let exerciseAudioButtonDebound = null
let exerciseAudioButtonRequestController = null

const ExerciseAudioButton = ({
  requiresAudioAssistance,
  eventAudioType,
  hasPreviowsPopups = false,
  getAudio,
  fillColor,
  previousAudioId,
  isVisible,
  onActivityEvent,
  exerciseGuid
}) => {
  const [alive, setAlive] = useState(false)
  const [audioExerciseGuid, setAudioExerciseGuid] = useState(null)
  const [previousAudioType, setPreviousAudioType] = useState(previousAudioId)
  const [audioIdentifier, setAudioIdentifier] = useState(null)
  const [audioLoadStatus, setAudioLoadStatus] = useState(
    AUDIO_LOAD_STATUS.UNLOADED
  )
  const [userClickOnAudio, setUserClickOnAudio] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    setAlive(true)

    return () => {
      setAlive(false)
      destroyAudio()
    }
  }, [])

  useEffect(() => {
    return () => {
      destroyAudio()
    }
  }, [audioIdentifier])

  // Para las pistas o feedback, si se oculta durante reproducción detiene la reproducción
  useEffect(() => {
    if (!isVisible && audioLoadStatus === AUDIO_LOAD_STATUS.LOADED) {
      ExerciseAudioManager.stopAudio(audioIdentifier, previousAudioId)
    }
  }, [isVisible])

  useEffect(() => {
    if (alive) setPreviousAudioType(previousAudioId)
  }, [previousAudioId])

  useEffect(() => {
    if (audioIdentifier && audioLoadStatus === AUDIO_LOAD_STATUS.LOADED) {
      const audio = ExerciseAudioManager.store[audioIdentifier]?.audio
      if (audio) {
        audio.onended = () => setIsPlaying(false)
        audio.onpause = () => setIsPlaying(false)
        audio.onplay = () => setIsPlaying(true)
      }
    }
  }, [audioIdentifier, audioLoadStatus])

  // Al crearse el botón -> Solicitar audio ("abortable")
  useEffect(() => {
    let isComponentMounted = true
    let controller = null

    const initAudio = async () => {
      // Al cambiar de ejercicio, se resetean los valores y elimina el audio
      destroyAudio()
      setAudioLoadStatus(AUDIO_LOAD_STATUS.UNLOADED)

      if (audioExerciseGuid !== exerciseGuid && isComponentMounted) {
        if (requiresAudioAssistance) {
          controller = new AbortController()
          try {
            await getSpeech(controller.signal)
          } catch (error) {
            if (error.name === 'AbortError') {
              // Ignoramos error de abort ya que es esperado durante cleanup
              return
            }
            console.warn('Error getting speech:', error)
          }
        }

        if (isComponentMounted) {
          setAudioExerciseGuid(exerciseGuid)
          setUserClickOnAudio(false)
        }
      }
    }

    initAudio()

    return () => {
      isComponentMounted = false
      if (controller) {
        controller.abort()
      }
    }
  }, [exerciseGuid])

  // Al cargarse el audio, si es necesario se reproduce automaticamente
  useEffect(() => {
    if (
      audioLoadStatus === AUDIO_LOAD_STATUS.LOADED &&
      (requiresAudioAssistance || userClickOnAudio) &&
      !hasPreviowsPopups
    ) {
      ExerciseAudioManager.playAudio(audioIdentifier, previousAudioType)

      if (userClickOnAudio) {
        textToSpeechAnalysisEvent()
        setUserClickOnAudio(false)
      }
    }
  }, [audioLoadStatus, userClickOnAudio, hasPreviowsPopups])

  const getSpeech = async (signal) => {
    setAudioLoadStatus(AUDIO_LOAD_STATUS.LOADING)
    // Obtener audio
    try {
      const audio = await getAudio(signal)

      setAudioIdentifier(ExerciseAudioManager.addAudio(audio)) // Store audio
      setAudioLoadStatus(AUDIO_LOAD_STATUS.LOADED)
    } catch (error) {
      setAudioLoadStatus(AUDIO_LOAD_STATUS.ERROR)
    }
  }

  // Al pulsar el botón -> (si no está sonando) play audio (stop resto)
  //                    -> (si    está sonando) play audio (stop resto)
  const toogleAudioPlaying = () => {
    clearTimeout(exerciseAudioButtonDebound)
    exerciseAudioButtonDebound = setTimeout(() => {
      if (audioLoadStatus === AUDIO_LOAD_STATUS.LOADED) {
        const isAudioPlaying = ExerciseAudioManager.toogleAudio(
          audioIdentifier,
          previousAudioId
        )
        setIsPlaying(isAudioPlaying)

        if (isAudioPlaying) {
          textToSpeechAnalysisEvent()
        }
      } else if (audioLoadStatus === AUDIO_LOAD_STATUS.UNLOADED) {
        exerciseAudioButtonRequestController = new AbortController()
        getSpeech(exerciseAudioButtonRequestController.signal)
        setUserClickOnAudio(true)
      }
    }, 300)
  }

  const textToSpeechAnalysisEvent = () => {
    onActivityEvent({
      name: 'TTS Clicked',
      properties: {
        object: 'activity',
        ui_element_id: eventAudioType
      }
    })
  }

  const destroyAudio = () => {
    if (audioIdentifier) {
      ExerciseAudioManager.removeAudio(audioIdentifier, previousAudioType)
    }
  }

  return (
    <div>
      <button
        // disabled={audioLoadStatus !== AUDIO_LOAD_STATUS.LOADED}
        className={`speak-button ${isPlaying ? 'playing' : ''} ${
          audioLoadStatus === AUDIO_LOAD_STATUS.LOADING ? 'loading' : ''
        }`}
        onClick={toogleAudioPlaying}
      >
        <IconSvg type="audio" />
      </button>
    </div>
  )
}

export default ExerciseAudioButton
