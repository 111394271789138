import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import {
  getSoundAssistance,
  getEffectsUserConfig
} from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import StreakCard from './components/StreakCard/StreakCard'
import StreakProgress from './components/StreakProgress/StreakProgress'
import './RewardDailyGoal.scss'
import PopupWrapped from '../../PopupWrapped'
import { getStreakAllGamification } from '../../../../../assets/data/api'
import { useGamificationActivityContext } from '../../../../../context/GamificationActivityContext/useGamificationActivityContext'
import TextContainer from '../../../../atoms/TextContainer/TextContainer'
import { BREAKPOINTS } from '../../../../../_practice-components/constants/uiConstants'
import Drawer from '../../../../atoms/Drawer/Drawer'
import PropTypes from 'prop-types'

const RewardDailyGoal = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const [streakDayCurrent, setStreakDayCurrent] = useState()
  const [streakInfo, setStreakInfo] = useState()
  const [coinsDailyGoalCurrent, setCoinsDailyGoalCurrent] = useState()
  const { setDailyGoalInProgress } = useGamificationActivityContext()
  const isMobile = window.innerWidth <= BREAKPOINTS.smallMobile

  const rewards = useSelector((s) => ({
    coinsDailyGoal: s.rewards.coinsDailyGoal,
    coinsAccumulated: s.rewards.coinsAccumulated,
    coinsStreak: s.rewards.coinsStreak,
    streakDay: s.rewards.streakDay
  }))

  const [BackgroundCircleImage] = useState(
    `${process.env.PUBLIC_URL}/assets/graphics/images/background-gradient-circle.svg`
  )

  useEffect(() => {
    setDailyGoalInProgress(false)
    getStreak()

    setTimeout(() => {
      Analysis.sendEvent(Analysis.EVENT.COINS_EARNED, {
        coins_daily_goal_earned: coinsDailyGoalCurrent,
        coins_accumulated: rewards.coinsAccumulated
      })
    }, 800)

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Daily Goal Popup Viewed']
    )

    getSoundAssistance() &&
      getEffectsUserConfig() === 1 &&
      playSoundEffect('rewardDailyGoal_' + getCurrentLangString())
  }, [])

  const getStreak = async () => {
    const dataStreak = await getStreakAllGamification()

    if (dataStreak && dataStreak.currentStreak === 0) {
      handleCheck()
      return
    }
    if (dataStreak) {
      setCoinsDailyGoalCurrent(dataStreak.mainRule?.rewardAmount)
      setStreakDayCurrent(dataStreak.currentStreak)
      setStreakInfo(dataStreak.streakInfo)
    }
  }

  const renderStreakCards = () => {
    if (isMobile) {
      const currentDay = streakDayCurrent

      if (currentDay === 1) {
        return (
          <>
            <StreakCard streakDay={1} step={1} streakInfo={streakInfo} />
            <StreakCard streakDay={1} step={2} streakInfo={streakInfo} />
            <StreakCard streakDay={1} step={3} streakInfo={streakInfo} />
          </>
        )
      }

      if (currentDay >= 5) {
        return (
          <>
            <StreakCard
              streakDay={currentDay}
              step={3}
              streakInfo={streakInfo}
            />
            <StreakCard
              streakDay={currentDay}
              step={4}
              streakInfo={streakInfo}
            />
            <StreakCard
              streakDay={currentDay}
              step={5}
              streakInfo={streakInfo}
            />
          </>
        )
      }

      return (
        <>
          <StreakCard
            streakDay={currentDay}
            step={currentDay - 1}
            streakInfo={streakInfo}
          />
          <StreakCard
            streakDay={currentDay}
            step={currentDay}
            streakInfo={streakInfo}
          />
          <StreakCard
            streakDay={currentDay}
            step={currentDay + 1}
            streakInfo={streakInfo}
          />
        </>
      )
    }

    return (
      <>
        <StreakCard
          streakDay={streakDayCurrent}
          step={1}
          streakInfo={streakInfo}
        />
        <StreakCard
          streakDay={streakDayCurrent}
          step={2}
          streakInfo={streakInfo}
        />
        <StreakCard
          streakDay={streakDayCurrent}
          step={3}
          streakInfo={streakInfo}
        />
        <StreakCard
          streakDay={streakDayCurrent}
          step={4}
          streakInfo={streakInfo}
        />
        <StreakCard
          streakDay={streakDayCurrent}
          step={5}
          streakInfo={streakInfo}
        />
      </>
    )
  }

  const renderContent = () => (
    <>
      {streakDayCurrent && streakInfo && (
        <div className="reward-popup reward-daily-goal">
          <div
            className="reward-popup__decoration"
            style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
          ></div>
          {renderSoundFXController()}
          <button className="reward-popup__close-icon" onClick={handleCheck}>
            <CloseIcon />
          </button>
          <TextContainer titleText={translate('reward_daily_goal_text_1')} />
          <div className="reward-popup__subtitle">
            {translate('reward_daily_goal_streak_text')}
          </div>
          <div className="reward-daily-goal__streak-cards">
            {renderStreakCards()}
          </div>
          <div className="reward-daily-goal__streak-progress">
            <StreakProgress currentStreak={streakDayCurrent} />
          </div>
        </div>
      )}
    </>
  )

  return (
    <>
      {isMobile ? (
        <div className="reward-daily-goal-drawer">
          <Drawer isOpen={true} direction="bottom" onClose={handleCheck}>
            {renderContent()}
          </Drawer>
        </div>
      ) : (
        <PopupWrapped onClick={handleCheck}>
          {renderContent()}
          <div className="reward-popup__backdrop" onClick={handleCheck}></div>
        </PopupWrapped>
      )}
    </>
  )
}

RewardDailyGoal.propTypes = {
  handleCheck: PropTypes.func.isRequired
}

export default RewardDailyGoal
