import React, { useEffect, useState } from 'react'
import cn from '@aqrojo/class-names'
import ReactDOM from 'react-dom'
import useModalSimple from './useModalSimple'
import { ReactComponent as IconClose } from '../../../assets/icons/icon_xclose.svg'
import TextContainer from '../../atoms/TextContainer/TextContainer'
import { useIsMobile } from '../../../hooks/useIsMobile'
import Drawer from '../../atoms/Drawer/Drawer'
import { getCurrentLangString } from '../../../i18n/initI18n'
import audioEs from '../../../assets/sound/es/exerciseAudios/new_mission_es.mp3'
import audioEn from '../../../assets/sound/en/exerciseAudios/new_mission_en.mp3'
import audioPt from '../../../assets/sound/pt/exerciseAudios/new_mission_pt.mp3'

import { getSoundAssistance } from '../../../services/settingsService'
import { ExerciseAudioManager } from '../../../_practice-components/utils/exerciseAudioManager'

const AUDIO_FILES = {
  es: audioEs,
  en: audioEn,
  pt: audioPt
}

function ModalSimple({
  onCloseStart = () => {},
  onCloseComplete = () => {},
  startHandle = () => {},
  open = false,
  showModal = false,
  withCloseButton = false,
  modalTopIcon,
  title,
  message,
  buttonText,
  customClass,
  audioId,
  playSoundEffect
}) {
  const PORTAL_ID = '__blueberry-modal-container-simple'
  const isMobile = useIsMobile()
  const langCode = getCurrentLangString()
  const audioFile = AUDIO_FILES[langCode]

  const { root, container, shadow } = useModalSimple(
    PORTAL_ID,
    showModal,
    startHandle
  )

  const [BackgroundCircleImage] = useState(
    `${process.env.PUBLIC_URL}/assets/graphics/images/background-gradient-circle.svg`
  )

  const [audioIdentifier, setAudioIdentifier] = useState(null)

  const handleAutoPlayAudio = () => {
    const audioToPlay = AUDIO_FILES[langCode]
    if (audioToPlay && getSoundAssistance()) {
      const audio = new Audio(audioToPlay)
      const newAudioId = ExerciseAudioManager.addAudio(audio)
      setAudioIdentifier(newAudioId)
      ExerciseAudioManager.playAudio(newAudioId)
    }
  }

  const handleManualPlayAudio = () => {
    const audioToPlay = AUDIO_FILES[langCode]
    if (audioToPlay) {
      if (!audioIdentifier) {
        const audio = new Audio(audioToPlay)
        const newAudioId = ExerciseAudioManager.addAudio(audio)
        setAudioIdentifier(newAudioId)
        ExerciseAudioManager.playAudio(newAudioId)
      } else {
        if (!ExerciseAudioManager.isPlaying(audioIdentifier)) {
          ExerciseAudioManager.playAudio(audioIdentifier)
        }
      }
    }
  }

  useEffect(() => {
    if (showModal) {
      handleAutoPlayAudio()
    }

    return () => {
      if (audioIdentifier) {
        ExerciseAudioManager.stopAllAudios()
      }
    }
  }, [showModal, langCode])

  const modalContent = (
    <>
      {isMobile ? (
        <Drawer direction="bottom" showCloseButton={false} onClose={() => {}}>
          <div
            ref={container}
            className={cn('modal__content', {
              show: open
            })}
          >
            <div
              className="modal__content-decoration"
              style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
            ></div>
            <div className="modal__content-wrapper-icon">
              <img src={modalTopIcon} alt="" />
            </div>
            <TextContainer
              titleText={title}
              audioFile={audioFile}
              playSoundEffect={handleManualPlayAudio}
            />
            <div
              className="modal__content-wrapper-init__subtitle"
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>

            {onCloseComplete && buttonText && (
              <button
                className="modal__content-wrapper-init__button"
                onClick={onCloseComplete}
              >
                {buttonText}
              </button>
            )}
            {withCloseButton === true && (
              <button
                className="modal__close-button"
                onClick={() => {
                  onCloseStart()
                }}
              >
                <IconClose className="iconClose" />
              </button>
            )}
          </div>
        </Drawer>
      ) : (
        <div
          ref={container}
          className={cn('modal__content', {
            show: open
          })}
        >
          <div
            className="modal__content-decoration"
            style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
          ></div>
          <div className="modal__content-wrapper-icon">
            <img src={modalTopIcon} alt="" />
          </div>
          <TextContainer
            titleText={title}
            audioFile={audioFile}
            playSoundEffect={handleManualPlayAudio}
          />
          <div
            className="modal__content-wrapper-init__subtitle"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>

          {onCloseComplete && buttonText && (
            <button
              className="modal__content-wrapper-init__button"
              onClick={onCloseComplete}
            >
              {buttonText}
            </button>
          )}
          {withCloseButton === true && (
            <button
              className="modal__close-button"
              onClick={() => {
                onCloseStart()
              }}
            >
              <IconClose className="iconClose" />
            </button>
          )}
        </div>
      )}
    </>
  )

  return ReactDOM.createPortal(
    showModal && (
      <div
        ref={root}
        className={'modal-simple' + (customClass ? ' ' + customClass : '')}
      >
        <div ref={shadow} className="modal__shadow-layer" />
        <div className="modal__content-wrapper-init">{modalContent}</div>
      </div>
    ),
    document.getElementById(PORTAL_ID)
  )
}

export default ModalSimple
