import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FEEDBACK_TYPE } from '../../../constants/FeedbackConstants'

import './feedbackIndicator.scss'

const FeedbackIndicator = ({ message, type, variant, pathAssetsSchool }) => {
  const [IconComponent, setIconComponent] = useState(null)

  const [svgCorrect_0] = useState(`${pathAssetsSchool}/correct_0.svg`)
  const [svgCorrect_1] = useState(`${pathAssetsSchool}/correct_1.svg`)
  const [svgCorrect_2] = useState(`${pathAssetsSchool}/correct_2.svg`)

  const [svgIncorrect_0] = useState(`${pathAssetsSchool}/incorrect_0.svg`)
  const [svgIncorrect_1] = useState(`${pathAssetsSchool}/incorrect_1.svg`)
  const [svgIncorrect_2] = useState(`${pathAssetsSchool}/incorrect_2.svg`)

  useEffect(() => {
    const ICONS = {
      [FEEDBACK_TYPE.CORRECT.toLowerCase()]: [
        svgCorrect_0,
        svgCorrect_1,
        svgCorrect_2
      ],
      [FEEDBACK_TYPE.INCORRECT.toLowerCase()]: [
        svgIncorrect_0,
        svgIncorrect_1,
        svgIncorrect_2
      ]
    }

    setIconComponent(() => ICONS?.[type]?.[variant])
  }, [type, variant])

  return (
    <div className={`feedback-indicator feedback-indicator--${type}`}>
      {IconComponent && <img src={IconComponent} alt="" />}
      <div className="feedback-indicator__message">{message}</div>
    </div>
  )
}

FeedbackIndicator.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  pathAssetsSchool: PropTypes.string.isRequired
}

export default FeedbackIndicator
