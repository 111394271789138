/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-deprecated */
import { createElement as createReactElement } from 'react'
import { createRoot } from 'react-dom/client'
import Phaser from 'phaser'
import Map from '../components/Map'
import Character from '../components/Character'
import UnitInformation from '../components/UnitInformation'
import LessonInformation from '../components/LessonInformation'
import QAElement from '../components/QAElement/QAElement'
import { store } from '../../../../store/configureStore'
import {
  setIsPhaserVisible,
  setIsGameUIVisible,
  setIsGameFromPop,
  enterUnit,
  enterLesson,
  exitUnit,
  setMoveCameraToPlayer,
  setMoveCameraToObject,
  setFirstBuildingImage,
  setNextZoomType,
  resetRespawn
} from '../../../../store/MetaberrySlice/MetaberrySlice'

import getLessons from '../utils/getLessons'
import { initLessons } from '../../../../store/UnitsSlice/UnitsSlice'
import areObjectsEqual from '../../../../util/areObjectsEqual'
import { getCourseGuid } from '../../../../assets/data/api'

import MetaLessonPanel from '../../../components/MetaLessonPanel/MetaLessonPanel'
import MetaUnitPanel from '../../../components/MetaUnitPanel/MetaUnitPanel'
// import MetaExitPanel from '../../../components/MetaExitPanel/MetaExitPanel'
import MapCourseBackButton from '../../../components/MapCourseBackButton/MapCourseBackButton'
import { v4 as uuidv4 } from 'uuid'
import {
  getRespawn,
  updateRespawn,
  getIsRankingShowed,
  getUserAvatar
} from '../../../../services/settingsService.js'
import {
  getPopupStore,
  updateBatteryPieces
} from '../../../../services/popupManagerService'
import { Analysis } from '../../../../services/analysisService'
import translate from '../../../../i18n/translate'
import { getPetAvatar } from '../../../../services/rewardsService'
import {
  getAloneUserData,
  getFamilyUserData
} from '../../../../services/userTypeService'
import { errorRedirection } from '../../../../services/errorService.js'
import { increaseCountProgress } from '../../../../util/loadingProgressBar.js'
import {
  setFirstBrokenBateryPending,
  setFirstUnlockedLessonPending,
  setFirstBateryCompletePending,
  setRewardBatteryUnlockPending
} from '../../../../store/PopupManagerSlice/PopupManagerSlice'
import { customStorage } from '../../../../util/customStorage.js'
import { getEntranceTile, getMapData } from '../utils/getMapData.js'
import checkUnitAvailable from '../../../../util/checkUnitAvailable.js'
import Drawer from '../../../atoms/Drawer/Drawer.jsx'

// Número de tiles visibles según dispositivo y nivel de zoom
const ZOOM_COURSE_MAP_VISIBLE_TILES = { desktop: [10, 18], phone: [7, 14] }
const ZOOM_UNIT_MAP_VISIBLE_TILES = { desktop: [10, 16], phone: [7, 13] }
const COURSE_BACKGRUIND_COLOR = '#6881D5'
const UNITS_BACKGRUIND_COLOR = '#000000'

// Para evitar lineas y artefactos en al manejar el zoom
const FIXED_ZOOMS = [
  // eslint-disable-next-line prettier/prettier
  0.03125, 0.0625, 0.125, 0.1875, 0.25, 0.3125, 0.375, 0.4375, 0.5, 0.5625,
  0.625, 0.6875, 0.75, 0.8125, 0.875, 0.9375, 1, 1.0625, 1.125, 1.1875, 1.25,
  1.3125, 1.375, 1.4375, 1.5, 1.5625, 1.625, 1.6875, 1.75, 1.8125, 1.875,
  1.9375, 2, 2.0625, 2.125, 2.1875, 2.25, 2.3125, 2.375, 2.4375, 2.5, 2.5625,
  2.625, 2.6875, 2.75, 2.8125, 2.875, 2.9375, 3, 3.0625, 3.125, 3.1875, 3.25,
  3.3125, 3.375, 3.4375, 3.5, 3.5625, 3.625, 3.6875, 3.75, 3.8125, 3.875,
  3.9375, 4, 4.0625, 4.125, 4.1875, 4.25, 4.3125, 4.375, 4.4375, 4.5, 4.5625,
  4.625, 4.6875, 4.75, 4.8125, 4.875, 4.9375, 5, 5.0625, 5.125, 5.1875, 5.25,
  5.3125, 5.375, 5.4375, 5.5, 5.5625, 5.625, 5.6875, 5.75, 5.8125, 5.875,
  5.9375, 6, 6.0625, 6.125, 6.1875, 6.25, 6.3125, 6.375, 6.4375, 6.5, 6.5625,
  6.625, 6.6875, 6.75, 6.8125, 6.875, 6.9375, 7, 7.0625, 7.125, 7.1875, 7.25,
  7.3125, 7.375, 7.4375, 7.5, 7.5625, 7.625, 7.6875, 7.75, 7.8125, 7.875,
  7.9375, 8, 8.0625, 8.125, 8.1875, 8.25, 8.3125, 8.375, 8.4375, 8.5, 8.5625,
  8.625, 8.6875, 8.75, 8.8125, 8.875, 8.9375, 9, 9.0625, 9.125, 9.1875, 9.25,
  9.3125, 9.375, 9.4375, 9.5, 9.5625, 9.625, 9.6875, 9.75, 9.8125, 9.875,
  9.9375, 10
  // eslint-disable-next-line prettier/prettier
]

const UPDATE_THRESHOLD = 160 // Umbral de actualización (160ms)

export default class World extends Phaser.Scene {
  constructor() {
    super('MainScene')

    ocLog(window._getTestTime() + ' - MainScene const i tms-')
    increaseCountProgress()

    window.localStorage.removeItem('bb_map_created')

    this.isPointerDown = false
    this.cursorPressed = { up: false, down: false, left: false, right: false }

    this.previousLessons = []

    this.portalActive = null

    this.joystick = null
    this.joystickKeys = null

    this.backCourseMapButtonWrapper = null
    this.backCourseMapButton = null
    // this.exitPanel = null
    this.unitPanelWrapper = null
    this.unitPanel = null
    this.lessonPanelWrapper = null
    this.lessonPanel = null
    this.lockedPortalsPreviousImages = []
    this.exitIcon = null

    this.cameraZoomIndex = 0
    this.cameraZoomValues = null

    this.unsubscriberRedux = null

    this.isFirstMapLoad = true

    this.isLoading = true
    this.loadingJustFinished = false
    this.loadingFinished = true

    this.debounceUpdateLessons = null
    this.debouncePlayerAvatarChange = null
    this.debouncePetAvatarChange = null
    this.debounceUpdatePanel = null
    this.cameraMoving = false

    this.isCourseMap = false

    this.tryAgainUpdateLessonsCounter = 0

    this.isRankingShowed = getIsRankingShowed()

    this.grassStepsSoundPlayer = null
    this.grassStepsSoundPet = null
    this.woodStepsSoundPlayer = null
    this.woodStepsSoundPet = null

    this.isCurrentSizePhone = this.checkCurrentSizePhone()

    window.addEventListener('toggle-birdeye', this.birdeye.bind(this))

    window.addEventListener('player-avatar-change', async () => {
      clearTimeout(this.debouncePlayerAvatarChange)
      this.debouncePlayerAvatarChange = setTimeout(async () => {
        this.createCompleted = false
        await this.player.changeTexture(
          getUserAvatar().url,
          getUserAvatar().sprite_json
        )
        this.createCompleted = true
      }, 200)
    })

    window.addEventListener('pet-avatar-change', async () => {
      clearTimeout(this.debouncePetAvatarChange)
      this.debouncePetAvatarChange = setTimeout(async () => {
        this.createCompleted = false

        const currentPetAvatar = getPetAvatar()
        // ocLog('Change - currentPetAvatar', currentPetAvatar)

        // Crear, modificar o destruir mascota
        if (currentPetAvatar) {
          if (this.playerPet) {
            const animationJson = currentPetAvatar.animation.spriteJson
            const animationParsedJson = animationJson
              ? JSON.parse(animationJson)
              : null
            await this.playerPet.changeTexture(
              currentPetAvatar.animation.spriteImage,
              animationParsedJson
            )
          } else {
            // ocLog('createPlayerPet- currentPetAvatar', currentPetAvatar)
            await this.createPlayerPet(this.cameras.main)
          }
        } else {
          if (this.playerPet) {
            this.playerPet.destroy()
            this.playerPet = null
          }
        }
        this.createCompleted = true
      }, 200)
    })

    this.boundBeforeUnload = (event) => {
      window.localStorage.removeItem('bb_game_initialized')
      this.setPlayerRespawn(event)
    }

    window.addEventListener('beforeunload', this.boundBeforeUnload.bind(this))
    window.addEventListener('user-logout', this.setPlayerRespawn.bind(this))
    // bb_game_initialized -> 1: MainsScene pasó por constructor, 2: MainScene terminó create
    window.localStorage.setItem('bb_game_initialized', 1)
    window.addEventListener('switch-player', this.setPlayerRespawn.bind(this))
    window.addEventListener('exit-game-page', this.setPlayerRespawn.bind(this))

    // Eventos para que desde fuera del juego (popups) se inhabilite o habilite su uso al usuario (del "juego en sí")
    window.addEventListener('disable-game', this.disableGame.bind(this))
    window.addEventListener('enable-game', this.enableGame.bind(this))

    window.addEventListener(
      'close-dom-elements',
      this.closeDomElements.bind(this)
    )

    window.addEventListener('popstate', (event) => {
      const phaserDomElements = document.querySelectorAll('.phaser-dom')
      if (phaserDomElements?.length && phaserDomElements.length > 0) {
        phaserDomElements.forEach((e) => e.remove())
      }

      this.destroyUnitPanel()
      this.destroyLessonPanel()
      this.destroyBackCourseMapButton()
      this.destroyAllQaElements()
    })

    this.isWindowResizing = false
    this.resizeWindowTimer = null

    window.addEventListener('resize', this.handleWindowResize.bind(this))

    window.addEventListener(
      'enter-unit-from-react-component',
      this.enterPortalFromReactComponent.bind(this)
    )

    Analysis.sendEvent(Analysis.EVENT.START_MAP)

    this.createCompleted = false

    // Para automatizar QA
    this.qaElements = []

    ocLog(window._getTestTime() + ' - MainScene const f tms-')
    increaseCountProgress()

    this.accumulatedDelta = 0 // Delta time acumulado

    // Agregar listener para mover al personaje a una tile
    window.addEventListener(
      'move-character-to-tile',
      this.handleMoveCharacterToTile.bind(this)
    )

    // Agregar propiedades para almacenar las referencias a los roots
    this.qaElementRoot = null
    this.unitPanelRoot = null
    this.lessonPanelRoot = null
    this.backCourseMapButtonRoot = null
  }

  preload() {
    ocLog(window._getTestTime() + ' - MainScene preload i tms-')
    window.isGameReady = false
    increaseCountProgress()

    const graphicsPath = 'assets/graphics/'
    const signPath = 'assets/graphics/mapSign/'
    const batteryPath = 'assets/graphics/battery/'
    // Estas imágenes se utilizarán en UnitInformation
    this.load.image('sign_top_ranked', `${signPath}signRankedTop.png`)
    this.load.image('sign_bottom_ranked', `${signPath}signRankedBottom.png`)
    this.load.image('sign_circle', `${signPath}circle.png`)
    this.load.image('sign_top', `${signPath}signNoRankTop.png`)
    this.load.image('sign_bottom', `${signPath}signNoRankBottom.png`)
    this.load.image('sign_top_lock', `${signPath}signLockedTop.png`)
    this.load.image('sign_bottom_lock', `${signPath}signLockedBottom.png`)
    this.load.image('sign_star_back', `${signPath}starBack.png`)
    this.load.image('sign_star_front_big', `${signPath}starFrontBig.png`)
    this.load.image('sign_star_front_small', `${signPath}starFrontSmall.png`)

    // Estas imágenes se utilizarán en LessonInformation
    this.load.image('disabled', `${batteryPath}disabled.png`)
    this.load.image('blocked', `${batteryPath}blocked.png`)
    this.load.image('install', `${batteryPath}install.png`)
    this.load.image('empty', `${batteryPath}empty.png`)
    this.load.image('init', `${batteryPath}init.png`)
    this.load.image('charging', `${batteryPath}charging.png`)
    this.load.image('complete', `${batteryPath}complete.png`)
    this.load.image('start', `${batteryPath}start.png`)
    this.load.image('broked_start', `${batteryPath}broked_start.png`)
    this.load.image('exit', `${graphicsPath}exit.png`)

    // Sonido de pisadas
    this.load.audio('grass-footsteps', ['assets/audio/grass-footsteps.mp3'])
    this.load.audio('wood-footsteps', ['assets/audio/wood-footsteps.mp3'])

    ocLog(window._getTestTime() + ' - MainScene preload f tms-')
    increaseCountProgress()
  }

  handleWindowResize() {
    this.isWindowResizing = true

    // Destruir paneles usando los métodos existentes
    if (this.unitPanelWrapper) {
      this.destroyUnitPanel()
    }
    if (this.lessonPanelWrapper) {
      this.destroyLessonPanel()
    }
    if (this.portalActive) {
      this.portalActive.active = false
      this.portalActive = null
    }

    if (this.resizeWindowTimer) {
      clearTimeout(this.resizeWindowTimer)
    }

    this.resizeWindowTimer = setTimeout(() => {
      this.isWindowResizing = false
    }, 250)
  }

  getCharacterStandingTile(character) {
    const characterCenter = character.getCenter()
    const row = Math.floor(
      (characterCenter.y - this.world.mapMarginY) / this.tileSize
    )
    const column = Math.floor(
      (characterCenter.x - this.world.mapMarginX) / this.tileSize
    )

    return [column, row]
  }

  enterPortalFromReactComponent(event) {
    this.destroyBackCourseMapButton()
    this.destroyLessonPanel()
    this.destroyUnitPanel()
    this.cleanupAndDestroyExitIcon()

    this.enterPortal(false, event?.detail)
  }

  async enterPortal(isFromBackCourseMapButton, unitDataFromCourseProgress) {
    if (this.backCourseMapButton) {
      this.destroyBackCourseMapButton()
    }

    if (isFromBackCourseMapButton) {
      if (this.lessonPanel) {
        this.destroyLessonPanel()
      }

      this.cleanupAndDestroyExitIcon()

      this.portalActive = this.portals.filter((item) => item.type === 'exit')[0]
    }

    if (this.portalActive || unitDataFromCourseProgress) {
      if (this.portalActive) {
        this.portalActive.active = false
      }

      // Lo clono porque puede darse el caso (MUY FORZADO) que termine de moverse el persoje por teclado
      //  habiendo dado ya a "entrar en portal" durante el "loading" lo que deja this.portalActive a null
      const portalClon = !unitDataFromCourseProgress
        ? { ...this.portalActive }
        : { ...unitDataFromCourseProgress }
      const portalType = portalClon.type.toLowerCase()
      await this.showLoading()
      this.createCompleted = false

      switch (portalType) {
        // Mapa curso (muestra unidades) a mapa unidad (muestra lecciones)
        case 'unit': {
          let tileForRespawn = []
          if (!unitDataFromCourseProgress) {
            tileForRespawn = this.getCharacterStandingTile(this.player)
          } else {
            const units = store.getState().units
            const unitIndex = units.findIndex(
              (unit) => unit.unit_guid === portalClon.unit_guid
            )
            const courseGuid = await getCourseGuid()
            let entranceTile = await getEntranceTile(courseGuid, unitIndex)
            entranceTile = entranceTile.split(',')
            portalClon.unit_index = unitIndex
            tileForRespawn[0] = parseInt(entranceTile[0])
            tileForRespawn[1] = parseInt(entranceTile[1])
          }

          // ocLog('enter unit portalClon', portalClon)

          store.dispatch(
            enterUnit({
              action: 'ENTER_UNIT',
              unitGuid: portalClon.unit_guid,
              unitName: portalClon.unit_name,
              unitExitTile: [tileForRespawn[0] + 1, tileForRespawn[1] + 1]
            })
          )
          const enterLessonEvent = new CustomEvent('enter-lesson')
          window.dispatchEvent(enterLessonEvent)

          break
        }

        // Mapa unidad (muestra lecciones) a lección (actividades)
        case 'lesson': {
          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Enter Lesson Clicked'],
            {
              lesson_guid: portalClon.lesson_guid,
              lesson_name: portalClon.lesson_name
            }
          )

          store.dispatch(
            enterLesson({
              action: 'ENTER_LESSON',
              lessonGuid: portalClon.lesson_guid,
              areLessonsUpdatedAfterPracticing: false
            })
          )
          break
        }

        // Sale de mapa de unidad (muestra lecciones) a mapa de curso (muestra unidades)
        case 'exit': {
          this.previousLessons = []

          const metaberryState = store.getState().metaberry
          const unitGuid = metaberryState.unitGuid

          const lessons = await getLessons()
          const unitEnteredEventObject = await this.getUnitEventData(
            unitGuid,
            lessons
          )

          Analysis.sendEvent(Analysis.EVENT.EXIT_UNIT, unitEnteredEventObject)

          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Unit Exited'],
            unitEnteredEventObject
          )

          store.dispatch(exitUnit())

          const enterUnitEvent = new CustomEvent('enter-unit')
          window.dispatchEvent(enterUnitEvent)

          break
        }

        default:
          console.error('Portal desconocido')
          errorRedirection('/error-BBE-107', true)
      }

      if (portalType !== 'lesson') {
        // Cambia mapa ------------------
        // Destruir jugador
        if (this.player) {
          this.player.setPath(null)
          this.player.sprite.setAlpha(0)
          this.player.destroy()
          this.player = null
        }

        // Destruir mascota
        if (this.playerPet) {
          this.playerPet.setPath(null)
          this.playerPet.sprite.setAlpha(0)
          this.playerPet.destroy()
          this.playerPet = null
        }

        this.world.destroy()

        this.scene.run('BootScene')

        this.portalActive = null
      } else {
        // Abre actividades
        let challenge = portalClon.challenges[0]
        if (portalClon.status === 'broked_start') {
          const repairChallenges = portalClon.challenges.find(
            (auxChallenge) => auxChallenge.name === 'repair'
          )

          if (repairChallenges) {
            challenge = repairChallenges
          }
        }

        const currentState = store.getState()
        const courseGuid = await getCourseGuid()
        // const practiceSessionId = uuidv4()
        let playerGuid = null

        const aloneMemberData = getAloneUserData()
        if (aloneMemberData) {
          playerGuid = aloneMemberData?.guid
        } else {
          const currentPlayerData = getFamilyUserData()
          playerGuid = currentPlayerData?.guid
        }

        const startPracticeObject = {
          user_id: currentState.practice.mainUserId,
          player_id: playerGuid,
          program_id: currentState.practice.programId,
          course_id: courseGuid,
          // practice_session_id: practiceSessionId, de back al obtener ejercicio, aqui aun no
          unit_id: portalClon.unit_guid,
          lesson_id: portalClon.lesson_guid,
          lesson_name: portalClon.lesson_name,
          lesson_level: portalClon.lesson_level,
          lesson_status: portalClon.status
        }

        Analysis.sendEvent(
          Analysis.EVENT.START_ACTIVITY_SCREEN,
          startPracticeObject
        )

        Analysis.sendSegmentPageEvent(
          Analysis.SEGMENT_PAGE_CATEGORIES.Game,
          Analysis.SEGMENT_EVENTS['Challenge Intro Page Viewed'],
          startPracticeObject
        )

        this.createCompleted = true
        this.hideLoading()

        window.dispatchEvent(
          new CustomEvent('start-practice', { detail: portalClon })
        )
      }
    }
  }

  fadeScene(closeCircle, startFuncion, endFunction) {
    return new Promise((resolve, reject) => {
      const mainCamera = this?.cameras?.main
      if (!mainCamera) {
        endFunction && endFunction()
        resolve()
      } else {
        /*
          const topLeft = mainCamera.getWorldPoint(0, 0)
          No sé porqué pero despues de this.getLockedPortalsPreviousImages no coge el top left de lo que se ve
          Sin embargo las 2 siguiens parecen funcionar o no tener relevancia...
          No borro la línea comentada porque creo que es bueno recordarla
        */
        const topLeft = { x: 0, y: 0 } // mainCamera.getWorldPoint(0, 0) No sé porqué pero despues ^
        const bottomRight = mainCamera.getWorldPoint(
          this.game.canvas.width,
          this.game.canvas.height
        )
        let center = mainCamera.getWorldPoint(
          this.game.canvas.width / 2,
          this.game.canvas.height / 2
        )

        const circleRadius = Math.sqrt(
          center.x * center.x + center.y * center.y
        )

        if (this.player) {
          center = this.player.getCenter()
        }

        const startRadius = closeCircle ? circleRadius : 0
        const endRadius = closeCircle ? 0 : circleRadius
        const easeValue = closeCircle ? 'Quad.easeOut' : 'Quad.easeIn'
        let circ = new Phaser.Geom.Circle(center.x, center.y, startRadius)

        let fill = this.add
          .rectangle(
            topLeft.x,
            topLeft.y,
            bottomRight.x - topLeft.x,
            bottomRight.y - topLeft.y,
            0x3703a4
          )
          .setOrigin(0)
          .setDepth(20)
          .setVisible(!closeCircle)

        let gfx = this.make.graphics()
        let mask = gfx.createGeometryMask()

        mask.invertAlpha = true
        fill.setMask(mask)
        this.add.tween({
          targets: circ,
          duration: 800,
          delay: 100,
          ease: easeValue,
          props: { radius: endRadius },
          onUpdate: (tween) => {
            if (startFuncion) {
              startFuncion()
              startFuncion = null
            }

            if (!fill.visible) fill.setVisible(true)
            gfx.clear().fillStyle(0).fillCircleShape(circ)

            if (this.fromLoadingRectangle) {
              this.fromLoadingRectangle.destroy()
              this.fromLoadingRectangle = null
            }
          },
          onComplete: () => {
            endFunction && endFunction()

            mask.destroy()
            mask = null

            gfx.destroy()
            gfx = null

            fill.destroy()
            fill = null

            circ = null

            resolve()
          }
        })
      }
    })
  }

  async showLoading() {
    this.loadingFinished = false
    this.isLoading = true // Sino se pone a esta altura, durante disableGame vuelve a entrar en el portal

    this.disableGame()

    await this.fadeScene(
      true,
      () => {
        store.dispatch(setIsGameUIVisible(false))
      },
      () => {
        window.dispatchEvent(new CustomEvent('loading-started'))
        store.dispatch(setIsPhaserVisible(false))
      }
    )
  }

  hideLoading() {
    this.loadingJustFinished = true
    this.enableGame()
  }

  _createMouseEventListeners() {
    this.input.on('pointerdown', (pointer) => {
      this.isPointerDown = true
      this.onUserClick(pointer)
    })

    this.input.on('pointermove', () => {
      if (this.isPointerDown) {
        // TODO
      }
    })

    this.input.on('pointerup', () => {
      this.isPointerDown = false
    })

    this.input.keyboard.on('keydown', (event) => {
      // ocLog('key down: ' + event.code)
      // Si estaba en mitad de un path, pararlo en la tile que le toque
      this.player.setPath(null)

      switch (event.code) {
        case 'ArrowUp':
          this.cursorPressed.up = true
          break

        case 'ArrowDown':
          this.cursorPressed.down = true
          break

        case 'ArrowLeft':
          this.cursorPressed.left = true
          break

        case 'ArrowRight':
          this.cursorPressed.right = true
          break

        case 'Space':
        case 'Enter':
          // Se activa al levantar, pero dejo esto como referencia
          break
      }
    })

    this.input.keyboard.on('keyup', (event) => {
      // ocLog('key up: ' + event.code)
      switch (event.code) {
        case 'ArrowUp':
          this.cursorPressed.up = false
          break

        case 'ArrowDown':
          this.cursorPressed.down = false
          break

        case 'ArrowLeft':
          this.cursorPressed.left = false
          break

        case 'ArrowRight':
          this.cursorPressed.right = false
          break

        case 'Space':
        case 'Enter':
          this.enterPortalByKey()
          break

        case 'KeyE': {
          // window.dispatchEvent(new CustomEvent('reward-daily-goal'))
          break
        }
      }

      if (
        !this.cursorPressed.up &&
        !this.cursorPressed.down &&
        !this.cursorPressed.left &&
        !this.cursorPressed.right &&
        this.player
      ) {
        this.player.setPath(null)
      }
    })
  }

  async onUserClick(pointer) {
    if (pointer.leftButtonDown()) {
      const mousePosition = this.input.activePointer.positionToCamera(
        this.cameras.main
      )
      // const mousePosition = pointer.x, pointer.y;

      const mapTileWidth = this.world.tileMap.tileWidth
      const mapTileHeight = this.world.tileMap.tileHeight
      let destinationTileColumn = Math.floor(
        (mousePosition.x - this.world.mapMarginX) / mapTileWidth
      )
      let destinationTileRow = Math.floor(
        (mousePosition.y - this.world.mapMarginY) / mapTileHeight
      )

      // Si se hace sobre un edificio, el camino es hasta la entrada del edificio en lugar de la tile contreta
      const buildingDestination = this.changeDestinationIfBuilding(
        destinationTileColumn,
        destinationTileRow
      )
      if (buildingDestination) {
        destinationTileColumn = buildingDestination.column
        destinationTileRow = buildingDestination.row
      }

      const playerStandingTile = this.getCharacterStandingTile(this.player)

      const calculatedPath = await this.world.calculateBestPath(
        playerStandingTile[0],
        playerStandingTile[1],
        destinationTileColumn,
        destinationTileRow
      )

      this.player.setPath(calculatedPath)
    } else {
      this.player.setPath(null)
    }
  }

  changeDestinationIfBuilding(destinationTileColumn, destinationTileRow) {
    let buildingDestination = null
    let buildingIndex = null

    const tileProperties = this.world.getTilePropertiesByLayerAtColumnRow(
      destinationTileColumn,
      destinationTileRow,
      true
    )

    for (const layer in tileProperties) {
      if (tileProperties[layer].unitIndex !== undefined) {
        buildingIndex = tileProperties[layer].unitIndex
        break
      }
    }

    if (buildingIndex !== null) {
      // Buscar la entrada por indice en los portales
      for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
        const auxPortal = this.portals[p]
        if (auxPortal.type === 'unit' && auxPortal.index === buildingIndex) {
          buildingDestination = {
            column: auxPortal.spawn[0],
            row: auxPortal.spawn[1]
          }
          break
        }
      }
    }

    return buildingDestination
  }

  cameraToObject(objectX, objectY, callback) {
    if (this.unitPanel || this.lessonPanel) {
      this.cameraMoving = true

      const domPanel = document.querySelector(
        this.unitPanel ? '.meta-unit-panel' : '.meta-lesson-panel'
      )
      if (domPanel) {
        domPanel.style.visibility = 'hidden'
      }
    }

    if (this.backCourseMapButton) {
      const domBackCourseMapButton = document.querySelector(
        '.map-course-back-button'
      )
      if (domBackCourseMapButton) {
        domBackCourseMapButton.style.visibility = 'hidden'
      }
    }

    const camera = this.cameras.main
    const effectTimeMillis = 800

    camera.stopFollow()
    camera.removeBounds()

    camera.pan(
      objectX,
      objectY,
      effectTimeMillis,
      Phaser.Math.Easing.Linear,
      true,
      (_cameraP, _progressP) => {
        if (_progressP === 1) {
          camera.zoomTo(
            this.cameraZoomValues[0] * 2,
            effectTimeMillis,
            Phaser.Math.Easing.Sine.In,
            true,
            (_cameraZ, _progressZ, _zoom) => {
              // Para que el centrado se ajuste al zoom durante los propios cambios de zoom
              this.adjustGameToCenter(_zoom, false)

              if (_progressZ === 1 && callback) {
                callback()
              }
            }
          )
        }
      }
    )
  }

  cameraToPlayer(callback) {
    const playerPosition = this.player.spriteCenter
    const camera = this.cameras.main
    const effectTimeMillis = 800

    camera.zoomTo(
      this.cameraZoomValues[this.cameraZoomIndex],
      effectTimeMillis,
      Phaser.Math.Easing.Sine.Out,
      true,
      (_cameraZ, _progressZ, _zoom) => {
        // Para que el centrado se ajuste al zoom durante los propios cambios de zoom
        this.adjustGameToCenter(_zoom, true)

        if (_progressZ === 1) {
          camera.pan(
            playerPosition.x,
            playerPosition.y,
            effectTimeMillis,
            Phaser.Math.Easing.Linear,
            true,
            (_cameraP, _progressP) => {
              if (_progressP === 1) {
                camera.startFollow(this.player.sprite, true, 0.1, 0.1)

                if (callback) {
                  callback()
                }

                if (this.unitPanel || this.lessonPanel) {
                  this.cameraMoving = false
                  document.querySelector(
                    this.unitPanel ? '.meta-unit-panel' : '.meta-lesson-panel'
                  ).style.visibility = 'visible'
                }

                if (this.backCourseMapButton) {
                  document.querySelector(
                    '.map-course-back-button'
                  ).style.visibility = 'visible'
                }
              }
            }
          )
        }
      }
    )
  }

  areGameComponentsActives() {
    return (
      this?.input?.keyboard?.manager !== undefined &&
      this?.scene?.manager !== undefined &&
      this.input.keyboard.manager // Parece redundante, pero si se quita falla !!
    )
  }

  setKeyboardState(isEnabled) {
    if (!isEnabled) {
      for (const [key, value] of Object.entries(this.cursorPressed)) {
        this.cursorPressed[key] = false
      }
    }

    if (this.areGameComponentsActives()) {
      this.input.keyboard.manager.enabled = isEnabled
    }
  }

  disableGame(event) {
    // Desactiva todos los inputs
    this.game.input.enabled = false

    // Detener movimiento personaje
    if (this.player) {
      this.player.setPath(null)
      this.player.setDestination(null)
    }

    // Detener teclado
    this.setKeyboardState(false)

    this.isGameDisabled = true
  }

  enableGame(event) {
    // Reactiva todos los inputs
    this.game.input.enabled = true

    // Se comprueban otras posibles causas que no permitan activar el teclado
    const metaberryState = store.getState().metaberry
    if (
      !metaberryState.isPracticing &&
      !metaberryState.isInQuizz &&
      !metaberryState.isGameKeyboardBlocked
    ) {
      this.setKeyboardState(true)
    }

    this.isGameDisabled = false
  }

  checkCurrentSizePhone() {
    return store.getState().metaberry.currentDeviceType === 'phone'
  }

  pauseSceneWithSafety() {
    try {
      ocLog('scene -> pause')
      this.setKeyboardState(false)
      this.scene.pause()

      setTimeout(() => {
        try {
          this.sound.volume = 0
        } catch (ve) {}
      }, 200)
    } catch (error) {
      console.error('Error en pauseSceneWithSafety:', error)
      this.scene.resume()
      this.enableGame()
    }
  }

  resumeSceneWithSafety(isGameFromPop) {
    try {
      ocLog('scene -> resume')

      if (isGameFromPop) {
        store.dispatch(setIsGameFromPop(false))
      }

      this.scene.resume()
      this.setKeyboardState(true)
      setTimeout(() => {
        try {
          this.sound.volume = 1
        } catch (sv) {}
      }, 200)
    } catch (error) {
      console.error('Error en resumeSceneWithSafety:', error)
      this.enableGame()
    }
  }

  reduxListener() {
    const currentState = store.getState()
    const metaberryState = currentState.metaberry
    const isCurrentSizePhone = this.checkCurrentSizePhone()
    if (isCurrentSizePhone !== this.isCurrentSizePhone) {
      this.isCurrentSizePhone = this.checkCurrentSizePhone()
      this.setMainCameraInitialZoom()
    }

    // Comprobación para desbloquear teclado (sino phaser captura y bloquea todos los eventos
    if (this.scene && this.areGameComponentsActives()) {
      if (
        metaberryState.isPracticing ||
        metaberryState.isInQuizz ||
        (metaberryState.isGameKeyboardBlocked && !metaberryState.isGameFromPop)
      ) {
        this.pauseSceneWithSafety()
      }
      // Se comprueba que no esté desabilidato por otros motivos (this.isGameDisabled)
      else if (!this.isGameDisabled || metaberryState.isGameFromPop) {
        this.resumeSceneWithSafety(metaberryState.isGameFromPop)
      }
    }

    // Comprobación para actualizar lecciones si han cambiado
    const currentGuid = metaberryState.unitGuid
    if (currentGuid) {
      const currentUnit = currentState.units.find(
        ({ unit_guid }) => unit_guid === currentGuid
      )

      const currentLessons =
        currentUnit && currentUnit.lessons ? currentUnit.lessons : []

      const isFromProgressMap = customStorage.get(
        'bb_enter_unit_from_progress_map'
      )

      if (
        !isFromProgressMap &&
        this.previousLessons.length > 0 &&
        currentLessons.length > 0 &&
        !areObjectsEqual(currentLessons, this.previousLessons)
      ) {
        clearTimeout(this.debounceUpdateLessons)
        this.debounceUpdateLessons = setTimeout(async () => {
          this.updateLessonsPortals(currentLessons)
        }, 400)
      }

      this.previousLessons = JSON.parse(JSON.stringify(currentLessons))
    }

    // Comprobación para mover camara si se solicita
    const moveCameraToObjectActive = metaberryState.moveCameraToObjectActive

    if (moveCameraToObjectActive === true) {
      store.dispatch(setMoveCameraToObject({ active: false }))

      const moveCameraToObjectIndex = metaberryState.moveCameraToObjectIndex
      if (moveCameraToObjectIndex !== -1) {
        const battery = this.portals[moveCameraToObjectIndex]
        if (battery?.batteryPosition?.[0] && battery?.batteryPosition?.[1]) {
          const batteryX = (battery.batteryPosition[0] + 0.5) * this.tileSize
          const batteryY = (battery.batteryPosition[1] + 0.5) * this.tileSize
          this.cameraToObject(batteryX, batteryY, () => {
            store.dispatch(setMoveCameraToObject({ index: -1 }))
          })
        } else {
          store.dispatch(setMoveCameraToObject({ index: -1 }))
        }
      }
    }

    //  A jugador
    const moveCameraToPlayer = metaberryState.moveCameraToPlayer
    if (moveCameraToPlayer === true) {
      store.dispatch(setMoveCameraToPlayer(false))
      this.cameraToPlayer()
    }
  }

  // en login obtener de api y meter en X
  // en mover, entrar y salir actualizar en X
  //    en entrar o salir
  // como se si hay que usar lo que venía o lo que dicta el juego
  // en close mandar a api

  async setPlayerRespawn(event) {
    try {
      if (this.unsubscriberRedux) this.unsubscriberRedux()

      this.closeDomElements()

      const playerCenter = this.player.sprite.getCenter()
      const playerTileColumn =
        Math.floor((playerCenter.x - this.world.mapMarginX) / this.tileSize) + 1
      const playerTileRow =
        Math.floor((playerCenter.y - this.world.mapMarginY) / this.tileSize) + 1
      const metaberryState = store.getState().metaberry

      const updatedRespawn = {
        unit_guid: metaberryState.unitGuid,
        unit_name: metaberryState.unitName,
        row: playerTileRow,
        column: playerTileColumn
      }

      if (event.type === 'beforeunload') {
        updatedRespawn.timestamp = Date.now()
      }

      await updateRespawn(updatedRespawn)

      // Para sincronizar el final de esta función con el logout en logoutService
      event?.detail?.promiseResolve && event?.detail?.promiseResolve()
    } catch (e) {}
  }

  closeDomElements() {
    // Quitar paneles si estan abiertos
    try {
      this.destroyUnitPanel()
      this.destroyLessonPanel()
      this.destroyBackCourseMapButton()
      this.destroyAllQaElements()
    } catch (e) {}
  }

  craeteQaElement(identifier, destinationColumnAndRow, order) {
    const onClickAction = !destinationColumnAndRow
      ? () => {}
      : async () => {
          const standingTile = this.getCharacterStandingTile(this.player)
          const calculatedPath = await this.world.calculateBestPath(
            standingTile[0],
            standingTile[1],
            destinationColumnAndRow[0],
            destinationColumnAndRow[1]
          )

          this.player.setPath(calculatedPath)
        }

    const qaElementWrapper = this.createDomWrapper()
    const qaElement = createReactElement(QAElement, {
      qaIdentifier: identifier,
      qaAction: onClickAction,
      order
    })
    const qaElementRoot = createRoot(qaElementWrapper)
    qaElementRoot.render(qaElement)
    document.querySelector('body').appendChild(qaElementWrapper)

    const qaObject = {
      element: qaElement,
      wrapper: qaElementWrapper,
      root: qaElementRoot
    }
    this.qaElements.push(qaObject)

    return qaObject
  }

  destroyQaElement(qaObject) {
    if (!qaObject) return

    try {
      qaObject.root?.unmount()
      if (qaObject.wrapper && document.body.contains(qaObject.wrapper)) {
        document.querySelector('body').removeChild(qaObject.wrapper)
      }
    } catch (e) {
      console.warn('Error destroying QA element:', e)
    }
    qaObject.element = null
    qaObject.wrapper = null
    qaObject.root = null
  }

  destroyAllQaElements() {
    if (this.qaElements?.length) {
      for (let i = this.qaElements.length - 1; i >= 0; i--) {
        this.destroyQaElement(this.qaElements[i])
      }
      this.qaElements = []
    }
  }

  craeteQaAutationElements() {
    // Destruir previos si existen
    this.destroyAllQaElements()

    this.qaElements.push(this.craeteQaElement('main-stone_1', [30, 9], 1))
    this.qaElements.push(this.craeteQaElement('main-stone_2', [26, 8], 2))
    this.qaElements.push(this.craeteQaElement('main-stone_3', [21, 6], 3))
    this.qaElements.push(this.craeteQaElement('main-door_juices', [27, 7], 4))
    this.qaElements.push(this.craeteQaElement('juices-bottles', [2, 13], 5))
    this.qaElements.push(this.craeteQaElement('juices-juices_box', [13, 14], 6))
    this.qaElements.push(this.craeteQaElement('juices-exit', [7, 16], 7))
    this.qaElements.push(this.craeteQaElement('main-door_gym', [16, 6], 7))
  }

  async create(data) {
    ocLog(window._getTestTime() + ' - MainScene create i tms-')
    increaseCountProgress()

    if (this.loadingFinished) {
      await this.showLoading()
    }

    try {
      this.grassStepsSoundPlayer = this.sound.add('grass-footsteps')
      this.grassStepsSoundPet = this.sound.add('grass-footsteps')
      this.woodStepsSoundPlayer = this.sound.add('wood-footsteps')
      this.woodStepsSoundPet = this.sound.add('wood-footsteps')
    } catch (stepsError) {
      console.error(stepsError)
    }

    ocLog(window._getTestTime() + ' - MainScene create 1.1 tms-')
    increaseCountProgress()

    this.isRankingShowed = getIsRankingShowed()

    ocLog(window._getTestTime() + ' - MainScene create 1.2 tms-')
    increaseCountProgress()
    this.unsubscriberRedux = store.subscribe(this.reduxListener.bind(this)) // TODO unsuscribe al matar escena
    ocLog(window._getTestTime() + ' - MainScene create 1.3 tms-')
    increaseCountProgress()
    let respawnUnitGuid = null
    let respawnUnitName = null
    let respawnTile = null

    if (this.isFirstMapLoad) {
      this.isFirstMapLoad = false
      let respawn = getRespawn()

      // Asegurar que existe la unidad de respawn
      if (respawn?.unit_guid) {
        const currentState = store.getState()
        const currentUnits = currentState.units
        const hasRespanwUnit = currentUnits.find(
          (cUnit) => cUnit.unit_guid === respawn.unit_guid
        )

        if (!hasRespanwUnit) {
          await updateRespawn(null)

          store.dispatch(
            resetRespawn({
              unitGuid: null,
              lessonGuid: null,
              unitExitTile: null
            })
          )

          respawn = null
        }
      }

      respawnUnitGuid = respawn?.unit_guid
      respawnUnitName = respawn?.unit_name
      respawnTile =
        respawn && respawn.column && respawn.column
          ? [respawn.column, respawn.row]
          : null

      if (respawnUnitGuid) {
        const enterLessonEvent = new CustomEvent('enter-lesson')
        window.dispatchEvent(enterLessonEvent)
        ocLog(window._getTestTime() + ' - MainScene create 1.4 tms-')
        increaseCountProgress()
        store.dispatch(
          enterUnit({
            action: 'ENTER_UNIT',
            unitGuid: respawnUnitGuid,
            unitName: respawnUnitName,
            unitExitTile: null
          })
        )

        Analysis.sendEvent(Analysis.EVENT.ENTER_UNIT, {
          unit_guid: respawnUnitGuid,
          unit_name: respawnUnitName
        })
      }
    }

    const metaberryState = store.getState().metaberry
    const unitGuid = respawnUnitGuid || metaberryState.unitGuid
    ocLog(window._getTestTime() + ' - MainScene create 2.1 tms-')
    increaseCountProgress()
    await this.createMap(unitGuid)
    ocLog(window._getTestTime() + ' - MainScene create 2.2 tms-')
    increaseCountProgress()
    const camera = this.cameras.main
    camera.roundPixels = true
    camera.setBounds(
      0,
      0,
      this.world.tileMap.widthInPixels + this.world.mapMarginX,
      this.world.tileMap.heightInPixels + this.world.mapMarginY
    )

    this.resize({ event: 'custom-onCreate' })

    this.createMiniMap()
    ocLog(window._getTestTime() + ' - MainScene create 3 tms-')
    const playerStartPoint = await this.createCharacter(
      metaberryState,
      camera,
      respawnTile
    )

    await this.createPlayerPet(playerStartPoint, camera)
    ocLog(window._getTestTime() + ' - MainScene create 4 tms-')
    this._createMouseEventListeners()

    // Set up the arrows to control the player
    this.cursors = this.input.keyboard.createCursorKeys()

    const joystickScene = this.scene.get('JoystickScene')
    if (joystickScene) {
      this.joystick = joystickScene.getJoystick()
      if (this.joystick) {
        this.joystickKeys = this.joystick.createCursorKeys()
      }
    }

    this.controls = new Phaser.Cameras.Controls.SmoothedKeyControl({
      camera: this.cameras.main,
      // zoomIn: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
      // zoomOut: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
      acceleration: 0.06,
      drag: 0.0005,
      maxSpeed: 1.0
    })
    ocLog(window._getTestTime() + ' - MainScene create 5 tms-')
    this.scale.on('resize', this.resize, this)

    this.createCompleted = true
    this.hideLoading()

    this.craeteQaAutationElements()

    window.localStorage.setItem('bb_game_initialized', 2)
    ocLog(window._getTestTime() + ' - MainScene create f tms-')
  }

  createMiniMap() {
    /* // miniMap WIP
    const maxSize = this.tileSize * 2
    let miniMapRatio = maxSize / this.world.tileMap.widthInPixels
    const miniMapRatioY = maxSize / this.world.tileMap.heightInPixels
    if (miniMapRatio > miniMapRatioY) miniMapRatio = miniMapRatioY
    const miniMapWidth = this.world.tileMap.widthInPixels * miniMapRatio

    const miniMapCamera = this.cameras.add(
      this.game.canvas.width - miniMapWidth - this.tileSize / 4, // x
      this.tileSize / 2,
      miniMapWidth,
      this.world.tileMap.heightInPixels * miniMapRatio
    )
    miniMapCamera.setBackgroundColor('rgba(0,0,0,0.5)')
    miniMapCamera.setOrigin(0)
    miniMapCamera.setAlpha(0.5)
    miniMapCamera.setZoom(miniMapRatio)
    // */
  }

  async createCharacter(metaberryState, camera, respawnTile) {
    const startPointTileCentered = {}
    const tileMap = this.world.tileMap
    let spawnTile = null

    if (metaberryState.action === 'EXIT_UNIT' || respawnTile) {
      spawnTile = respawnTile || metaberryState.unitExitTile

      // Si al salir de una unidad no hay 'unitExitTile' definida es que entro a la unidad desde inicio (sin portal)
      //  por lo que al salir de ella hay que buscar la tile de spawn que le corresponde
      if (!spawnTile) {
        const respawn = getRespawn()
        if (respawn && respawn.unit_guid) {
          spawnTile = this.portals.find(
            (portal) => portal.unit_guid === respawn.unit_guid
          ).spawn
          spawnTile = [spawnTile[0] + 1, spawnTile[1] + 1]
        }
      }
    }

    let isCharacterAtStart = true
    if (spawnTile) {
      // Se comprueba que la tile de inicio inidicada sea valida
      const isOutOfRange =
        spawnTile[0] - 1 < 0 ||
        spawnTile[1] - 1 < 0 ||
        spawnTile[0] - 1 >= tileMap.width ||
        spawnTile[1] - 1 >= tileMap.height

      if (!isOutOfRange) {
        const tileSolidId =
          this.world.finderGrid[spawnTile[1] - 1][spawnTile[0] - 1]
        const isOnSolid = this.world.walkableTiles.indexOf(tileSolidId) === -1

        if (!isOnSolid) {
          isCharacterAtStart = false

          startPointTileCentered.x =
            spawnTile[0] * this.tileSize - this.tileSize / 2

          startPointTileCentered.y =
            spawnTile[1] * this.tileSize - this.tileSize / 2
        }
      }
    }

    if (isCharacterAtStart) {
      const startPoint = tileMap.findObject(
        'interactives',
        (obj) => obj.name === 'start'
      )

      startPointTileCentered.x =
        (Math.floor(startPoint.x / tileMap.tileWidth) + 1) * this.tileSize -
        this.tileSize / 2

      startPointTileCentered.y =
        (Math.floor(startPoint.y / tileMap.tileHeight) + 1) * this.tileSize -
        this.tileSize / 2
    }

    this.player = new Character(
      this,
      'player',
      this.tileSize,
      true,
      startPointTileCentered,
      camera,
      this.world.mapMarginX,
      this.world.mapMarginY,
      false,
      this.grassStepsSoundPlayer,
      this.woodStepsSoundPlayer
    )

    const userAvatar = getUserAvatar()
    await this.player.generateCharacter(userAvatar.url, userAvatar.sprite_json)
    // await this.player.generateCharacter('./assets/avatares-2/Character_14/PNG/walking.png')

    return startPointTileCentered
  }

  async createPlayerPet(camera) {
    const currentPetAvatar = getPetAvatar()
    // ocLog('createPlayerPet - inside - currentPetAvatar', currentPetAvatar)

    if (currentPetAvatar && currentPetAvatar.animation) {
      const playerTile = this.getCharacterStandingTile(this.player)
      // ocLog('playerTile', playerTile)
      let playerPetTile = null
      for (let x = -1; x < 2; x++ && !playerPetTile) {
        for (let y = -1; y < 2; y++ && !playerPetTile) {
          if (x !== 0 || y !== 0) {
            if (
              this.world.isTileWalkable(playerTile[0] + x, playerTile[1] + y)
            ) {
              playerPetTile = [playerTile[0] + x, playerTile[1] + y]
            }
          }
        }
      }
      // ocLog('playerPetTile', playerPetTile)

      const startPoint = {
        x: playerPetTile[0] * this.tileSize - this.tileSize / 2,
        y: playerPetTile[1] * this.tileSize - this.tileSize / 2
      }

      this.playerPet = new Character(
        this,
        'playerPet',
        this.tileSize,
        true,
        { x: startPoint.x, y: startPoint.y },
        camera,
        this.world.mapMarginX,
        this.world.mapMarginY,
        true,
        this.grassStepsSoundPet,
        this.woodStepsSoundPet
      )

      const animationJson = currentPetAvatar.animation?.spriteJson
      const animationParsedJson = animationJson
        ? JSON.parse(animationJson)
        : null

      currentPetAvatar.animation &&
        (await this.playerPet.generateCharacter(
          currentPetAvatar.animation?.spriteImage,
          animationParsedJson
        ))

      // const ptTile = this.getCharacterStandingTile(this.playerPet)
      // ocLog('ptTile', ptTile)

      return true
    }
  }

  getCameraZoomByWidthAndHeight(
    desiredWidth,
    desiredHeight,
    returnMax,
    returnUnfixed
  ) {
    const zoomX = this.game.canvas.width / desiredWidth
    const zoomY = this.game.canvas.height / desiredHeight
    const zoomMin = zoomX < zoomY ? zoomX : zoomY
    const zoomMax = zoomX > zoomY ? zoomX : zoomY

    let result = returnMax ? zoomMax : zoomMin

    // Dejar zoom en números que no generen decimales "desajustados", para evitar artefactos entre las tiles
    if (!returnUnfixed) {
      for (let z = 1, zMax = FIXED_ZOOMS.length; z < zMax; z++) {
        if (FIXED_ZOOMS[z] > result) {
          const diffUp = FIXED_ZOOMS[z] - result
          const diffDown = result - FIXED_ZOOMS[z - 1]

          result = diffUp < diffDown ? FIXED_ZOOMS[z] : FIXED_ZOOMS[z - 1]
          break
        }
      }
    }

    return result
  }

  setMainCameraInitialZoom() {
    const camera = this.cameras.main
    const tileMap = this?.world?.tileMap

    if (camera && tileMap) {
      // Calculate zoom levels based on map type and device
      const visibleTilesConfig = this.isCourseMap
        ? ZOOM_COURSE_MAP_VISIBLE_TILES
        : ZOOM_UNIT_MAP_VISIBLE_TILES

      const deviceType = this.isCurrentSizePhone ? 'phone' : 'desktop'
      const zoomValues = []

      // Add zoom levels for each number of visible tiles
      visibleTilesConfig[deviceType].forEach((tileCount) => {
        const zoom = this.getCameraZoomByWidthAndHeight(
          (tileMap.widthInPixels / tileMap.width) * tileCount,
          (tileMap.heightInPixels / tileMap.height) * tileCount,
          false
        )
        zoomValues.push(zoom)
      })

      // Add fit-to-window zoom level
      const fitZoom = this.getCameraZoomByWidthAndHeight(
        tileMap.widthInPixels,
        tileMap.heightInPixels,
        true
      )
      const nearZoom = zoomValues[0]
      zoomValues[0] = fitZoom < nearZoom ? nearZoom : fitZoom

      // Add full map view zoom level
      const farZoom = this.getCameraZoomByWidthAndHeight(
        tileMap.widthInPixels,
        tileMap.heightInPixels,
        false,
        true
      )
      zoomValues.push(farZoom)

      // Store zoom levels from closest to farthest
      this.cameraZoomValues = zoomValues

      const initialZoom = this.cameraZoomValues[this.cameraZoomIndex]
      store.dispatch(
        setNextZoomType(
          this.cameraZoomIndex === this.cameraZoomValues.length - 1
            ? 'in'
            : 'out'
        )
      )

      camera.setZoom(initialZoom)
    }
  }

  birdeye() {
    if (this.cameras && this.cameras.main) {
      // Cycle to next zoom level
      const currentZoomIndex = this.cameraZoomIndex
      this.cameraZoomIndex =
        (this.cameraZoomIndex + 1) % this.cameraZoomValues.length
      const nextZoom = this.cameraZoomValues[this.cameraZoomIndex]

      store.dispatch(
        setNextZoomType(
          this.cameraZoomIndex === this.cameraZoomValues.length - 1
            ? 'in'
            : 'out'
        )
      )

      this.cameras.main.zoomTo(
        nextZoom,
        1200,
        currentZoomIndex > this.cameraZoomIndex
          ? Phaser.Math.Easing.Sine.In // Zoom in easing
          : Phaser.Math.Easing.Sine.Out, // Zoom out easing
        true,
        (_camera, _progress, _zoom) => {
          // Adjust centering during zoom transition
          this.adjustGameToCenter(_zoom)
        }
      )

      const zoomEventObject = {
        context: this.isCourseMap ? 'world' : 'unit',
        type: currentZoomIndex > this.cameraZoomIndex ? 'zoom in' : 'zoom out',
        zoom_level: this.cameraZoomIndex
      }

      const currentUnitGuid = store.getState().metaberry.unitGuid
      if (currentUnitGuid) {
        zoomEventObject.unit_id = currentUnitGuid
      }

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Map Zoomed Clicked'],
        zoomEventObject
      )
    }
  }

  adjustGameToCenter(customZoom, setBounds = true) {
    // Detener movimiento
    if (this.player) {
      this.player.setPath(null)
      this.player.setDestination(null)
    }
    if (this?.playerPet?.isCharacterDone) {
      this.playerPet.setPath(null)
      this.playerPet.setDestination(null)
    }

    // Modificar margenes para centrado de mapa
    const oldMarginX = this.world.mapMarginX
    const oldMarginY = this.world.mapMarginY

    //  - Mapa
    this.world.calculateMarginToCenterMap(customZoom)

    //  - Objetos (posicionados por el mapa: carteles, baterías...)
    for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
      const portal = this.portals[p]
      if (portal.type !== 'exit') {
        portal.information.updateMapMargins(
          this.world.mapMarginX,
          this.world.mapMarginY
        )
      }
    }

    if (this.exitIcon) {
      this.exitIcon.x -= oldMarginX - this.world.mapMarginX
      this.exitIcon.y -= oldMarginY - this.world.mapMarginY
    }

    //  - Personaje
    if (this.player) {
      this.player.updateMapMargins(this.world.mapMarginX, this.world.mapMarginY)
    }

    if (this.playerPet?.isCharacterDone) {
      this.playerPet.updateMapMargins(
        this.world.mapMarginX,
        this.world.mapMarginY
      )
    }

    //  - Límites para cámara
    if (setBounds === true) {
      this.cameras.main.setBounds(
        0,
        0,
        this.world.tileMap.widthInPixels + this.world.mapMarginX,
        this.world.tileMap.heightInPixels + this.world.mapMarginY
      )
    }
  }

  async resize(eventData) {
    this.setMainCameraInitialZoom()

    // Si no es el inicial (eventData es null en el rezize inicial)
    if (eventData) {
      this.adjustGameToCenter(this.cameras.main.zoom)
    }
  }

  async createMap(unitGuid) {
    ocLog(window._getTestTime() + ' - MainScene createMap i tms-')
    increaseCountProgress()
    this.isCourseMap = unitGuid === undefined || unitGuid === null
    const courseGuid = await getCourseGuid()

    if (this.isCourseMap) {
      const currentState = store.getState()
      const programId = currentState.practice.programId
      const currentUnits = currentState.units
      // ocLog('currentUnits', currentUnits)
      let unitsLocked = null
      let unitsAvailables = null

      if (currentUnits && currentUnits.length && currentUnits.length > 0) {
        unitsLocked = 0
        unitsAvailables = 0

        for (let u = 0, uMax = currentUnits.length; u < uMax; u++) {
          const isUnitAvailable = checkUnitAvailable(currentUnits[u])

          if (isUnitAvailable) unitsAvailables++
          else unitsLocked++
        }
      }

      Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['World Entered'], {
        program_id: programId,
        course_id: courseGuid,
        number_unit_locked: unitsLocked,
        number_unit_available: unitsAvailables
      })
    }

    const camera = this.cameras.main
    const backgroundColorString = !this.isCourseMap
      ? UNITS_BACKGRUIND_COLOR
      : COURSE_BACKGRUIND_COLOR
    const backgroundColor = Phaser.Display.Color.HexStringToColor(
      backgroundColorString
    ).color
    camera.setBackgroundColor(backgroundColor)

    const mapData = await getMapData(courseGuid, unitGuid)
    this.world = new Map(this, mapData)

    ocLog(window._getTestTime() + ' - MainScene createMap 1 tms-')
    increaseCountProgress()
    await this.world.generateMap(camera.zoom)
    this.tileSize = this.world.tileMap.tileWidth
    ocLog(window._getTestTime() + ' - MainScene createMap 2 tms-')

    await this.createPortals(unitGuid)

    ocLog(window._getTestTime() + ' - MainScene createMap f tms-')

    return true
  }

  createPortalInformation(portal, tileSize, isPreviousLessonAvailable) {
    if (portal.information) {
      const frame = portal.information.split(',').map((item) => parseInt(item))

      if (portal.type === 'unit') {
        portal.information = new UnitInformation(
          this,
          tileSize,
          frame[0] * tileSize,
          frame[1] * tileSize,
          this.world.mapMarginX,
          this.world.mapMarginY,
          portal.name,
          // portal.status,
          portal.lessons_total,
          portal.complete_lessons_total,
          portal.is_available,
          portal.publication_state,
          portal.index,
          portal.unit_guid
        )

        // Añadir a la grid de búsqueda de caminos las patas del cartel
        this.world.updateFinderGridCell(frame[0], frame[1] + 1, false)
        this.world.updateFinderGridCell(frame[0] + 1, frame[1] + 1, false)
      } else {
        // Notifica al onboarding si hay baterías rotas
        const firstBrokenBatery = new CustomEvent('onboarding-broken-battery')
        if (portal.status === 'broked_start') {
          // ocLog('>>>>>>>> BROKEN BATTERY from start')
          window.dispatchEvent(firstBrokenBatery)
        }

        portal.batteryPosition = frame

        // ocLog('new LessonInformation')
        portal.information = new LessonInformation(
          this,
          frame[0] * tileSize,
          frame[1] * tileSize,
          this.world.mapMarginX,
          this.world.mapMarginY,
          tileSize,
          tileSize,
          portal.challenges,
          portal.status,
          portal?.is_available === 1,
          isPreviousLessonAvailable,
          () => this.onLessonClicked(portal)
        )
      }

      portal.information.generateInformation()
    }
  }

  onLessonClicked(portal) {
    if (this.portalActive) {
      this.closePortalPanel(this.portalActive)
    }

    this.openPortalPanel(portal, this.portals[portal.index - 1], portal.index)
  }

  async getUnitEventData(unitGuid, lessons) {
    const currentState = store.getState()
    const programId = currentState.practice.programId
    const courseId = await getCourseGuid()
    const unit = currentState.units.filter(
      (_unit) => _unit.unit_guid === unitGuid
    )
    const unitName = unit && unit[0] ? unit[0].unit_name : ''

    let lessonsInstall = 0
    let lessonsEmpty = 0
    let lessonsInit = 0
    let lessonsCharging = 0
    let lessonsComplete = 0
    let lessonsStart = 0
    let lessonsLocked = 0
    // let lessonsBrokedStart = 0

    for (let l = 0, lMax = lessons.length; l < lMax; l++) {
      if (lessons[l].is_available === 1) {
        switch (lessons[l].status) {
          case 'install':
            lessonsInstall++
            break

          case 'empty':
            lessonsEmpty++
            break

          case 'init':
            lessonsInit++
            break

          case 'charging':
            lessonsCharging++
            break

          case 'complete':
            lessonsComplete++
            break

          case 'start':
            lessonsStart++
            break

          /*
          case 'broked_start':
            lessonsBrokedStart++
            break
          */

          default:
            lessonsLocked++
        }
      } else {
        lessonsLocked++
      }
    }

    return {
      program_id: programId,
      course_id: courseId,
      unit_id: unitGuid,
      unit_name: unitName,
      // where_clicked:,
      number_lessons_locked: lessonsLocked,
      // number_lessons_broked_start: lessonsBrokedStart,
      number_lessons_install: lessonsInstall,
      number_lessons_empty: lessonsEmpty,
      number_lessons_init: lessonsInit,
      number_lessons_charging: lessonsCharging,
      number_lessons_complete: lessonsComplete,
      number_lessons_start: lessonsStart
    }
  }

  // A unidades o lecciones
  async createPortals(unitGuid) {
    // TODO: Repartir y avisar si no hay huecos suficientes en el mapa

    let lessons = null
    let isCourseMap = true

    if (unitGuid) {
      isCourseMap = false
      lessons = await getLessons(unitGuid)

      const unitEnteredEventObject = await this.getUnitEventData(
        unitGuid,
        lessons
      )

      const mapMillis = window.localStorage.getItem('bb_map_created')
      let timeSpentInMap = 0
      if (mapMillis) {
        timeSpentInMap = new Date().getTime() - parseInt(mapMillis)
      }

      unitEnteredEventObject.time_spent_in_pap = timeSpentInMap

      const isFromProgressMap = customStorage.get(
        'bb_enter_unit_from_progress_map'
      )
      customStorage.remove('bb_enter_unit_from_progress_map')

      unitEnteredEventObject.from = !isFromProgressMap
        ? 'from_map'
        : 'progress_map'

      Analysis.sendEvent(Analysis.EVENT.ENTER_UNIT, unitEnteredEventObject)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Unit Entered'],
        unitEnteredEventObject
      )

      // TODO itentar centralizar la obtención y actualización de lecciones un poco más
      store.dispatch(
        initLessons({
          unit_guid: unitGuid,
          lessons: lessons
        })
      )
    }

    window.localStorage.setItem('bb_map_created', new Date().getTime())

    const portalType = isCourseMap ? 'unit' : 'lesson'
    const apiPortals = isCourseMap ? store.getState().units : lessons // Unidades o lecciones desde API y almacenadas
    const mapPortals = this.world.mapObjets.interactives.list // "Huecos" (objetos) en el mapa para desplegar las unidades o lecciones
      .filter((item) => item.name.toLowerCase() === portalType)
      .reverse()

    const tileSize = this.world.tileMap.tileWidth

    // Asigna las lecciones del curso a las del mapa (lessons api <= objects map)
    if (!mapPortals || mapPortals.length === 0) {
      console.error(
        'Error: There is no object on the map for ' +
          (isCourseMap ? 'unit' : 'lesson') +
          ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-103' : '/error-BBE-104', true)
    }

    if (!apiPortals || apiPortals.length === 0) {
      console.error(
        'Error: There are no ' + (isCourseMap ? 'unit' : 'lesson') + ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-101' : '/error-BBE-102', true)
    }

    if (mapPortals.length < apiPortals.length) {
      console.error(
        'Error: There are no enough objects on the map for the ' +
          (isCourseMap ? 'unit' : 'lessons') +
          ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-105' : '/error-BBE-106', true)
    }

    // -
    this.portals = []

    for (let p = 0, pMax = apiPortals.length; p < pMax; p++) {
      const apiPortal = apiPortals[p]
      const mapPortal = apiPortal.order
        ? mapPortals.filter((item) => item.index === apiPortal.order - 1)[0]
        : mapPortals[p]

      const currentPortal = {
        ...apiPortal,
        ...mapPortal,
        type: portalType,
        name:
          portalType === 'unit' ? apiPortal.unit_name : apiPortal.lesson_name,
        index: p,
        active: false,
        uuid: uuidv4()
      }

      currentPortal.spawn = currentPortal.spawn
        .split(',')
        .map((item) => parseInt(item))

      if (currentPortal.information) {
        let isPreviousLessonAvailable = true
        if (portalType === 'lesson' && p > 0) {
          isPreviousLessonAvailable = apiPortals[p - 1]?.is_available === 1
        }

        this.createPortalInformation(
          currentPortal,
          tileSize,
          isPreviousLessonAvailable
        )
      }

      if (currentPortal.ellipse) {
        currentPortal.x += currentPortal.width / 2
        currentPortal.y += currentPortal.height / 2
      }

      this.portals.push(currentPortal)
    }

    const exitPortal = this.world.mapObjets.interactives.list.filter(
      (item) => item.name.toLowerCase() === 'exit'
    )[0]

    if (exitPortal) {
      const currentPortal = {
        ...exitPortal,
        type: 'exit',
        name: translate('exit'),
        active: false,
        uuid: uuidv4()
      }

      this.portals.push(currentPortal)
    }
  }

  tryAgainUpdateLessons() {
    if (this.tryAgainUpdateLessonsCounter < 3) {
      this.tryAgainUpdateLessonsCounter++
      setTimeout(() => {
        const currentState = store.getState()
        const currentGuid = currentState.metaberry.unitGuid
        if (currentGuid) {
          const currentUnit = currentState.units.find(
            ({ unit_guid }) => unit_guid === currentGuid
          )
          const currentLessons =
            currentUnit && currentUnit.lessons ? currentUnit.lessons : []
          this.updateLessonsPortals(currentLessons)
        } else {
          this.tryAgainUpdateLessons()
        }
      }, 150)
    } else {
      this.tryAgainUpdateLessonsCounter = 0
      window.location.reload()
    }
  }

  // A unidades o lecciones
  async updateLessonsPortals(lessons) {
    const firstBrokenBatery = new CustomEvent('onboarding-broken-battery')
    let statusChangeDetected = false
    let availabilityChanged = false

    // ocLog('this.portals', this.portals)

    for (let i = 0, iMax = this.portals.length; i < iMax; i++) {
      const currentPortal = this.portals[i]

      if (currentPortal.type !== 'exit' && currentPortal.type !== 'unit') {
        const updatedPortal = lessons.find(
          ({ lesson_guid }) => lesson_guid === currentPortal.lesson_guid
        )

        // ESTO ES UN INTENTO DE CONTROL PARA EL ERROR
        //  Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'is_available')
        // MIENTRAS NO TENGAMOS INFORMACION MAS PRECISA O MANERA DE REPRODUCIRLO
        if (!updatedPortal) {
          console.error('Error updatedPortal undefined')
          this.tryAgainUpdateLessons()
          return
        } else {
          this.tryAgainUpdateLessonsCounter = 0
        }

        // Notifica al onboarding un cambio en la disponibilidad
        if (currentPortal.is_available !== updatedPortal.is_available) {
          store.dispatch(setFirstUnlockedLessonPending(true))

          // Indica que objeto mover, pero no mueve camara (eso se hace desde el popup)
          store.dispatch(setMoveCameraToObject({ index: i }))
          store.dispatch(setRewardBatteryUnlockPending(true))
          availabilityChanged = true
        }

        // Notifica al onboarding un cambio en el estado
        if (currentPortal.status !== updatedPortal.status) {
          // ocLog('>>>>>>>> STATUS CHANGED')
          if (
            !statusChangeDetected &&
            updatedPortal.status !== 'disabled' &&
            currentPortal.status !== 'disabled' &&
            updatedPortal.status !== 'broked_start' &&
            currentPortal.status !== 'complete'
          ) {
            statusChangeDetected = true
          }

          if (updatedPortal.status === 'complete') {
            updateBatteryPieces(
              JSON.stringify(updatedPortal.pieces.have) ===
                JSON.stringify(updatedPortal.pieces.pieces)
            )

            // window.dispatchEvent(firstBateryComplete)
            store.dispatch(setFirstBateryCompletePending(true))
          }

          if (updatedPortal.status === 'broked_start') {
            // ocLog('>>>>>>>> BROKEN BATTERY')
            window.dispatchEvent(firstBrokenBatery)
            store.dispatch(setFirstBrokenBateryPending(true))
          }
        }

        // Si el portal está activo y tiene panel abierto, actualizar en lugar de destruir
        const hasToUpdatePanel =
          updatedPortal.status !== currentPortal.status ||
          updatedPortal.isAvailable !== currentPortal.isAvailable ||
          updatedPortal.pieces !== currentPortal.pieces

        if (hasToUpdatePanel && this.lessonPanel && currentPortal.active) {
          window.dispatchEvent(
            new CustomEvent('update-lesson-panel', {
              detail: {
                status: updatedPortal.status,
                isAvailable: updatedPortal.is_available,
                pieces: updatedPortal.pieces
              }
            })
          )
        }

        currentPortal.is_available = updatedPortal.is_available
        currentPortal.status = updatedPortal.status
        currentPortal.challenges = updatedPortal.challenges
        currentPortal.lessonchallenges = updatedPortal.challenges
        currentPortal.lesson_level = updatedPortal.lesson_level
        currentPortal.pieces = updatedPortal.pieces

        if (currentPortal.information) {
          let isPreviousLessonAvailable = true

          if (i > 0) {
            isPreviousLessonAvailable =
              lessons.find(
                ({ lesson_guid }) =>
                  lesson_guid === this.portals[i - 1].lesson_guid
              )?.is_available === 1
          }

          currentPortal.information.editLessonIcon(
            currentPortal.status,
            currentPortal?.is_available === 1,
            isPreviousLessonAvailable,
            this.world.mapMarginX,
            this.world.mapMarginY
          )
        }
      }
    }

    // Actualizar las imágenes de las lecciones bloqueadas después de que todos los portales se hayan actualizado
    if (availabilityChanged) {
      await this.getLockedPortalsPreviousImages()
    }
  }

  // TODO mejora a poligonos
  isInsideRectangle(point, rectX, rectY, rectWidth, rectHeight) {
    const xAxis = point.x > rectX && point.x < rectX + rectWidth
    const yAxis = point.y > rectY && point.y < rectY + rectHeight

    return xAxis && yAxis
  }

  isInsideEllipse(point, ellipseX, ellipseY, ellipseWidth, ellipseHeight) {
    const radiusX = ellipseWidth / 2
    const radiusY = ellipseHeight / 2
    const normalized = { x: point.x - ellipseX, y: point.y - ellipseY }

    return (
      (normalized.x * normalized.x) / (radiusX * radiusX) +
        (normalized.y * normalized.y) / (radiusY * radiusY) <=
      1
    )
  }

  isInsidePolygon(point, polygon) {
    let isInside = false

    let minX = polygon[0].x + this.world.mapMarginX
    let maxX = polygon[0].x + this.world.mapMarginX
    let minY = polygon[0].y + this.world.mapMarginY
    let maxY = polygon[0].y + this.world.mapMarginY

    for (let n = 1; n < polygon.length; n++) {
      const q = polygon[n]
      minX = Math.min(q.x + this.world.mapMarginX, minX)
      maxX = Math.max(q.x + this.world.mapMarginX, maxX)
      minY = Math.min(q.y + this.world.mapMarginY, minY)
      maxY = Math.max(q.y + this.world.mapMarginY, maxY)
    }

    if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY)
      return false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      if (
        polygon[i].y > point.y !== polygon[j].y > point.y &&
        point.x <
          ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) /
            (polygon[j].y - polygon[i].y) +
            polygon[i].x
      ) {
        isInside = !isInside
      }
    }

    return isInside
  }

  isInsideArea(point, portal) {
    if (portal.polygon) {
      return this.isInsidePolygon(point, portal.polygon)
    } else if (portal.ellipse)
      return this.isInsideEllipse(
        point,
        portal.x + this.world.mapMarginX,
        portal.y + this.world.mapMarginY,
        portal.width,
        portal.height
        // portal.rotation
      )
    else
      return this.isInsideRectangle(
        point,
        portal.x + this.world.mapMarginX,
        portal.y + this.world.mapMarginY,
        portal.width || 1,
        portal.height || 1
      )
  }

  convertGamePositionToScreenPosition(gameX, gameY) {
    const camera = this.cameras.main

    /*
    // Get world position
    const worldTransformMatrix = gameObject.getWorldTransformMatrix()
    const x = worldTransformMatrix.getX(0, 0)
    const y = worldTransformMatrix.getY(0, 0)

    // Convert world position into canvas pixel space
    const displayScale = camera.scaleManager.displayScale
    const cameraMatrix = camera.matrix
    const tx =
      cameraMatrix.getX(x - camera.scrollX, y - camera.scrollY) / displayScale.x
    const ty =
      cameraMatrix.getY(x - camera.scrollX, y - camera.scrollY) / displayScale.y
    const screenPosition = { x: Math.round(tx), y: Math.round(ty) }
    */

    // La de arriba funciona, pero esta más simple parece ir bien
    const screenPosition = {
      x: Math.round((gameX - camera.worldView.x) * camera.zoom),
      y: Math.round((gameY - camera.worldView.y) * camera.zoom)
    }

    return screenPosition
  }

  /* Indica si el personaje está en la parte izquierda del mapa para mostrar el desplegable al otro lado
  isLeftSide() {
    const mapColumns = this.world.tileMap.width
    const spritePosition = this.player.getCenter()
    const playerTileColumn =
      Math.floor((spritePosition.x - this.world.mapMarginX) / this.tileSize) + 1

    return mapColumns / 2 > playerTileColumn
  }
  */

  // Obtine recortes del juego de la unida/lección anterior para
  //  mostrar como pista en paneles de unidades/lecciones bloqueadas
  async getLockedPortalsPreviousImages() {
    this.lockedPortalsPreviousImages = []

    const previousImageZoom =
      this.cameraZoomValues[this.cameraZoomValues.length - 1] * 2 // Doubled the zoom

    // Desvincular cámara
    const mainCamera = this.cameras.main
    const savedZoom = mainCamera.zoom
    mainCamera.removeBounds()
    mainCamera.stopFollow()
    mainCamera.setZoom(previousImageZoom)
    mainCamera.setOrigin(0)

    // Ocultar personaje para que no salga en las capturas
    this.player.sprite.visible = false
    if (this.playerPet) {
      this.playerPet.sprite.visible = false
    }

    const areUnitPortals = this.portals.some((portal) => portal.type === 'unit')

    // Doubled the tile dimensions
    const snapWidthInTiles = areUnitPortals ? 19 : 7 // Doubled from 9/3
    const snapHeightInTiles = areUnitPortals ? 17 : 7 // Doubled from 8/3
    const areaModifierX = areUnitPortals ? 9 : 3 // Doubled from 4/1
    const areaModifierY = areUnitPortals ? 13 : 3 // Doubled from 6/1
    const snapWidth = Math.round(
      this.tileSize * snapWidthInTiles * previousImageZoom
    )
    const snapHeight = Math.round(
      this.tileSize * snapHeightInTiles * previousImageZoom
    )

    // Encontrar la última lección desbloqueada
    let lastUnlockedPortal = null
    for (let i = this.portals.length - 1; i >= 0; i--) {
      const currentPortal = this.portals[i]
      if (currentPortal.type === 'lesson' && currentPortal.is_available === 1) {
        lastUnlockedPortal = currentPortal
        break
      }
    }

    // Si encontramos una lección desbloqueada, capturar su imagen
    let lastUnlockedImage = null
    if (lastUnlockedPortal) {
      const topLeftTileAnchor = lastUnlockedPortal.batteryPosition
      const areaX =
        topLeftTileAnchor[0] * this.tileSize -
        areaModifierX * this.tileSize +
        this.world.mapMarginX
      const areaY =
        topLeftTileAnchor[1] * this.tileSize -
        areaModifierY * this.tileSize +
        this.world.mapMarginY

      // Centrar camara auxiliar en el area deseada
      mainCamera.setScroll(areaX, areaY)

      // Obtener imagen como snapshot del area de la camara secundaria
      lastUnlockedImage = await new Promise((resolve, reject) => {
        this.game.renderer.snapshotArea(0, 0, snapWidth, snapHeight, (image) =>
          resolve(image.src)
        )
      })
    }

    // Llenar el array con la imagen de la última lección desbloqueada
    for (let p = this.portals.length - 1; p > 0; p--) {
      const currPortal = this.portals[p]
      if (currPortal.type !== 'exit') {
        this.lockedPortalsPreviousImages.unshift(lastUnlockedImage)
      }
    }

    // Esta corresponde al portal inicial que siempre está desbloqueado
    this.lockedPortalsPreviousImages.unshift(null)

    // Personaje visible de nuevo
    this.player.sprite.visible = true
    if (this.playerPet) {
      this.playerPet.sprite.visible = true
    }

    // Revincular cámara
    mainCamera.setOrigin(0.5)
    mainCamera.setZoom(savedZoom)

    this.adjustGameToCenter(savedZoom)
    this.player.setCameraFollowCharacter(mainCamera)

    // Se guarda la imagen del primer portal para usar en el onboarding
    if (
      this.lockedPortalsPreviousImages &&
      this.lockedPortalsPreviousImages.length > 1
    ) {
      const firstBuildingImage = this.lockedPortalsPreviousImages[1]
      if (firstBuildingImage) {
        store.dispatch(setFirstBuildingImage(firstBuildingImage))
      }
    }
  }

  // Obtener posición jugador en pantalla
  getPlayerScreenPosition(camera) {
    const playerTile = this.getCharacterStandingTile(this.player)

    let playerPosition = this.world.getTileWorldXY(
      playerTile[0],
      playerTile[1],
      camera
    )

    const halfTile = Math.round(this.tileSize / 2)
    playerPosition = this.convertGamePositionToScreenPosition(
      playerPosition.x + halfTile,
      playerPosition.y + halfTile
    )

    return playerPosition
  }

  getBatteryScreenPosition(portalBatteryPosition, camera) {
    let batteryPosition = this.world.getTileWorldXY(
      portalBatteryPosition[0],
      portalBatteryPosition[1],
      camera
    )
    batteryPosition = this.convertGamePositionToScreenPosition(
      batteryPosition.x,
      batteryPosition.y
    )

    return batteryPosition
  }

  showExitIcon(portal) {
    if (!this.exitIcon) {
      this.exitIcon = this.add
        .sprite(
          portal.x + portal.width / 2 + this.world.mapMarginX,
          portal.y + portal.height + this.world.mapMarginY,
          'exit'
        )
        .setOrigin(0.5, 0)
        .setDepth(9)
        .setDisplaySize(this.tileSize - 4, this.tileSize - 4)

      this.exitIcon.setInteractive({ cursor: 'pointer' })

      // Store event handlers as properties and bind them to this context
      this.exitIcon.pointerOverHandler = () => {
        if (this.exitIcon) {
          this.exitIcon.tint = 0x888888
        }
      }

      this.exitIcon.pointerOutHandler = () => {
        if (this.exitIcon) {
          this.exitIcon.tint = 0xffffff
        }
      }

      this.exitIcon.pointerDownHandler = (pointer, _x, _y, event) => {
        if (this.exitIcon) {
          // Detener la propagación del evento
          event.stopPropagation()

          const cleanupAndEnter = () => {
            this.cleanupAndDestroyExitIcon()
            this.enterPortal()
          }
          setTimeout(cleanupAndEnter.bind(this), 250)
        }
      }

      // Bind the handlers to maintain context
      this.exitIcon.on(
        'pointerover',
        this.exitIcon.pointerOverHandler.bind(this)
      )
      this.exitIcon.on('pointerout', this.exitIcon.pointerOutHandler.bind(this))
      this.exitIcon.on(
        'pointerdown',
        this.exitIcon.pointerDownHandler.bind(this)
      )
    }
  }

  cleanupAndDestroyExitIcon() {
    if (this.exitIcon) {
      try {
        // Remove event listeners
        this.exitIcon.off('pointerover', this.exitIcon.pointerOverHandler)
        this.exitIcon.off('pointerout', this.exitIcon.pointerOutHandler)
        this.exitIcon.off('pointerdown', this.exitIcon.pointerDownHandler)

        // Remove interactivity
        this.exitIcon.removeInteractive()

        // Destroy the sprite
        this.exitIcon.destroy()
      } catch (e) {
        console.warn('Error cleaning up exit icon:', e)
      } finally {
        this.exitIcon = null
      }
    }
  }

  createDomWrapper() {
    const wrapper = document.createElement('div')
    // wrapper.style.cssText = 'position:absolute;overflow:hidden;'
    return wrapper
  }

  destroyUnitPanel() {
    if (this.unitPanelWrapper) {
      try {
        // Desmontar el árbol de React - comprobamos qué root existe
        if (this.unitDrawerRoot) {
          this.unitDrawerRoot.unmount()
          this.unitDrawerRoot = null
        } else if (this.unitPanelRoot) {
          this.unitPanelRoot.unmount()
          this.unitPanelRoot = null
        }

        // Eliminar el wrapper del DOM
        if (
          this.unitPanelWrapper &&
          document.body.contains(this.unitPanelWrapper)
        ) {
          document.querySelector('body').removeChild(this.unitPanelWrapper)
        }
      } catch (e) {
        console.warn('Error destroying unit panel:', e)
      }

      // Limpiar todas las referencias
      this.unitPanel = null
      this.unitDrawer = null
      this.unitPanelWrapper = null
    }
  }

  destroyLessonPanel() {
    if (this.lessonPanelWrapper) {
      try {
        // Añadir limpieza de timeout sin modificar el código existente
        if (this.debounceUpdatePanel) {
          clearTimeout(this.debounceUpdatePanel)
          this.debounceUpdatePanel = null
        }

        // Desmontar el árbol de React - comprobamos qué root existe
        if (this.lessonDrawerRoot) {
          this.lessonDrawerRoot.unmount()
          this.lessonDrawerRoot = null
        } else if (this.lessonPanelRoot) {
          this.lessonPanelRoot.unmount()
          this.lessonPanelRoot = null
        }

        // Eliminar el wrapper del DOM
        if (
          this.lessonPanelWrapper &&
          document.body.contains(this.lessonPanelWrapper)
        ) {
          document.querySelector('body').removeChild(this.lessonPanelWrapper)
        }
      } catch (e) {
        console.warn('Error destroying lesson panel:', e)
      }

      // Limpiar todas las referencias
      this.lessonPanel = null
      this.lessonDrawer = null
      this.lessonPanelWrapper = null

      // Ayudar al garbage collector en Safari/iOS
      setTimeout(() => {}, 0)
    }
  }

  destroyBackCourseMapButton() {
    if (this.backCourseMapButtonWrapper) {
      try {
        if (this.backCourseMapButtonRoot) {
          this.backCourseMapButtonRoot.unmount()
          this.backCourseMapButtonRoot = null
        }
        if (
          this.backCourseMapButtonWrapper &&
          document.body.contains(this.backCourseMapButtonWrapper)
        ) {
          document
            .querySelector('body')
            .removeChild(this.backCourseMapButtonWrapper)
        }
      } catch (e) {
        console.warn('Error destroying back course map button:', e)
      }

      this.backCourseMapButton = null
      this.backCourseMapButtonWrapper = null
    }
  }

  async openPortalPanel(portal, previousPortal, index) {
    const courseGuid = await getCourseGuid()
    const previousPortalImage =
      this.lockedPortalsPreviousImages.length > index
        ? this.lockedPortalsPreviousImages[index]
        : null

    if (portal.type === 'unit') {
      const mainCamera = this.cameras.main

      // Obtener posición jugador en pantalla
      const playerPosition = this.getPlayerScreenPosition(mainCamera)
      this.playerScreenPosition = playerPosition

      this.unitPanelWrapper = this.createDomWrapper()
      // Crear referencia para evitar referencias circulares
      const self = this
      this.unitPanel = createReactElement(MetaUnitPanel, {
        isAvailable: checkUnitAvailable(portal),
        isRankingShowed: this.isRankingShowed,
        handleEnterButton: () => {
          if (self.unitPanel) {
            self.destroyUnitPanel()
            self.enterPortal()
          }
        },
        unitName: portal.name,
        previousUnitImage: previousPortalImage,
        ranking: portal.ranking,
        userGuid: store.getState().metaberry.userGuid,
        unitGuid: portal.unit_guid,
        previousUnitGuid: this.portals[index - 1]
          ? this.portals[index - 1].unit_guid
          : null,
        previousUnitName: this.portals[index - 1]
          ? this.portals[index - 1].unit_name
          : null,
        handleWhereButton: () => {
          const tileAnchor = this.portals[index - 1].spawn.map((x) =>
            parseInt(x)
          )
          const anchorX = tileAnchor[0] * this.tileSize
          const anchorY = tileAnchor[1] * this.tileSize

          self.disableGame()
          self.cameraToObject(anchorX, anchorY, () => {
            setTimeout(() => {
              self.cameraToPlayer(self.enableGame.bind(self))
            }, 600)
          })
        },
        playerScreenPosition: playerPosition,
        currentTileSize: Math.round(this.tileSize * mainCamera.zoom),
        pathSchool: store.getState().configuration.pathSchool,
        courseGuid: courseGuid,
        isFixed: this.isCurrentSizePhone
      })

      if (!this.isCurrentSizePhone) {
        this.unitPanelRoot = createRoot(this.unitPanelWrapper)
        this.unitPanelRoot.render(this.unitPanel)
      } else {
        this.unitDrawer = createReactElement(Drawer, {
          direction: 'bottom',
          isOpen: true,
          onClose: () => {
            self.destroyUnitPanel()
          },
          children: this.unitPanel
        })

        this.unitPanelRoot = createRoot(this.unitPanelWrapper)
        this.unitPanelRoot.render(this.unitDrawer)
      }

      document.querySelector('body').appendChild(this.unitPanelWrapper)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Unit Popup Viewed'],
        {
          program_id: store.getState().practice.programId,
          course_id: courseGuid,
          unit_guid: portal.unit_guid,
          unit_name: portal.name,
          unit_status: portal.status
        }
      )

      portal.active = true
      this.portalActive = portal
    }

    if (portal.type === 'lesson') {
      const metaberryState = store.getState().metaberry

      if (true /* metaberryState.areLessonsUpdatedAfterPracticing */) {
        const mainCamera = this.cameras.main

        // Obtener posición jugador en pantalla
        const playerPosition = this.getPlayerScreenPosition(mainCamera)
        this.playerScreenPosition = playerPosition

        // Obtener posición batería en pantalla
        const batteryPosition = this.getBatteryScreenPosition(
          portal.batteryPosition,
          mainCamera
        )

        // Crear panel informativo de lección
        this.lessonPanelWrapper = this.createDomWrapper()
        // Crear referencia para evitar referencias circulares
        const self = this
        this.lessonPanel = createReactElement(MetaLessonPanel, {
          status: portal.status,
          isAvailable:
            portal?.is_available !== undefined ? portal.is_available : 0,
          unitGuid: portal.unit_guid,
          lessonGuid: portal.lesson_guid,
          lessonName: portal.lesson_name,
          lessonNumber: index + 1,
          pieces: portal.pieces,
          previousLessonGuid: previousPortal
            ? previousPortal.lesson_guid
            : null,
          previousLessonName: previousPortal
            ? previousPortal.lesson_name
            : null,
          previousLessonImage: previousPortalImage,
          handleEnterButton: () => {
            if (self.lessonPanel) {
              self.destroyLessonPanel()
              self.playerScreenPosition = null
              setTimeout(() => {
                self.enterPortal()
              }, 200)
            }
          },
          playerScreenPosition: playerPosition,
          batteryScreenPosition: batteryPosition,
          currentTileSize: Math.round(this.tileSize * mainCamera.zoom),
          isFixed: this.isCurrentSizePhone
        })

        if (!this.isCurrentSizePhone) {
          this.lessonPanelRoot = createRoot(this.lessonPanelWrapper)
          this.lessonPanelRoot.render(this.lessonPanel)
        } else {
          this.lessonDrawer = createReactElement(Drawer, {
            direction: 'bottom',
            isOpen: true,
            onClose: () => {
              self.destroyLessonPanel()
            },
            children: this.lessonPanel
          })

          this.lessonPanelRoot = createRoot(this.lessonPanelWrapper)
          this.lessonPanelRoot.render(this.lessonDrawer)
        }

        document.querySelector('body').appendChild(this.lessonPanelWrapper)

        const aloneMemberData = getAloneUserData()
        let playerGuid = null
        if (aloneMemberData) {
          playerGuid = aloneMemberData?.guid
        } else {
          const currentPlayerData = getFamilyUserData()
          playerGuid = currentPlayerData?.guid
        }

        Analysis.sendSegmentTrackEvent(
          Analysis.SEGMENT_EVENTS['Lesson Practice Popup Viewed'],
          {
            program_id: store.getState().practice.programId,
            course_id: courseGuid,
            unit_id: portal.unit_guid,
            lesson_guid: portal.lesson_guid,
            lesson_name: portal.name,
            lesson_status: portal.status,
            user_id: playerGuid
          }
        )

        portal.active = true
        this.portalActive = portal
      }
    }

    if (portal.type === 'exit') {
      this.showExitIcon(portal)

      portal.active = true
      this.portalActive = portal
    }
  }

  closePortalPanel(portal) {
    if (portal?.type === 'unit') {
      if (this.unitPanelWrapper) {
        this.destroyUnitPanel()
      }
    }

    if (portal?.type === 'lesson') {
      if (this.lessonPanelWrapper) {
        this.destroyLessonPanel()
      }
      this.playerScreenPosition = null
    }

    if (portal?.active) {
      portal.active = false
    }

    // Garantizar que el estado del portal se restablezca correctamente
    if (portal) {
      portal.active = false
      this.portalActive = null

      // Marcar el portal con un timestamp de cuando fue cerrado
      portal.lastClosedTime = Date.now()
    }

    // Limpiar cualquier timeout pendiente para evitar condiciones de carrera
    if (this._enableInputsTimeout) {
      clearTimeout(this._enableInputsTimeout)
      this._enableInputsTimeout = null
    }

    // Verificar el estado completo de los inputs después de cerrar el panel
    this._enableInputsTimeout = setTimeout(() => {
      try {
        const metaberryState = store.getState().metaberry

        // Si el juego está deshabilitado por otras razones, no lo habilitamos
        if (this.isGameDisabled) {
          return
        }

        // Verificar todas las condiciones que podrían mantener los inputs bloqueados
        const inputsShouldBeEnabled =
          !metaberryState.isPracticing &&
          !metaberryState.isInQuizz &&
          !metaberryState.isGameKeyboardBlocked &&
          !this.isGameDisabled

        if (inputsShouldBeEnabled) {
          // Si no hay condiciones que bloqueen los inputs, habilitarlos
          this.game.input.enabled = true
          this.setKeyboardState(true)

          // También verificar si la escena está pausada cuando no debería estarlo
          if (this.scene.isPaused && this.scene.isPaused()) {
            this.resumeSceneWithSafety(false)
          }
        }
      } catch (error) {
        console.error('Error en closePortalPanel:', error)
        // Intentar habilitar los inputs incluso en caso de error, si es seguro hacerlo
        if (!this.isGameDisabled) {
          this.game.input.enabled = true
          this.setKeyboardState(true)
        }
      } finally {
        // Limpiar referencia al timeout
        this._enableInputsTimeout = null
      }
    }, 100) // Aumentar ligeramente el timeout para asegurar que otros procesos terminen
  }

  hasPopupToShow() {
    return Boolean(
      this.zoneTooltipWrapper ||
        this.unitPracticeTooltipWrapper ||
        this.lessonPracticeTooltipWrapper
    )
  }

  checkPortals(directionsPressed) {
    // Verificación defensiva
    if (!this.player || !this.portals || !this.portals.length) {
      return // Evitar error si no hay jugador o portales
    }

    const spritePosition = this.player.getCenter()
    // Según posición del jugador:
    // Para evitar errores entre cierre y apertura de portales, lo he separado en 2 bucles en lugar de 1
    // - Cerrar portales
    const keepPortalOpen = !this.isCurrentSizePhone
      ? false
      : !this.isCourseMap && !this.player.isMoving(directionsPressed)

    if (!keepPortalOpen) {
      for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
        const portal = this.portals[p]
        if (portal.active && !this.isInsideArea(spritePosition, portal)) {
          this.closePortalPanel(portal)

          if (portal.type === 'exit' && this.exitIcon) {
            this.cleanupAndDestroyExitIcon()
          }
        }
      }
    }

    // - Abrir portales
    if (!this.isLoading) {
      for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
        const portal = this.portals[p]
        const previousPortal = p > 0 ? this.portals[p - 1] : undefined

        const panelAlreadyOpen =
          this.unitPanelWrapper || this.lessonPanelWrapper

        // Verificar si el portal fue cerrado recientemente (menos de 300ms)
        const wasRecentlyClosed =
          portal.lastClosedTime && Date.now() - portal.lastClosedTime < 300

        const shouldOpenPanel =
          !panelAlreadyOpen &&
          !portal.active &&
          !wasRecentlyClosed && // No abrir si fue cerrado recientemente
          this.isInsideArea(spritePosition, portal) &&
          !this.hasPopupToShow() &&
          !this.cameraMoving &&
          !this.isWindowResizing

        if (
          shouldOpenPanel &&
          (!this.isCurrentSizePhone
            ? true
            : !this.player.isMoving(directionsPressed))
        ) {
          this.openPortalPanel(portal, previousPortal, p)
        }
      }
    }
  }

  enterPortalByKey() {
    if (this.portalActive) {
      if (this.unitPanel) {
        const isPortalAvailable = checkUnitAvailable(this.portalActive)
        if (isPortalAvailable) {
          this.destroyUnitPanel()
          this.enterPortal()
        }
      }

      if (this.lessonPanel && this.portalActive?.is_available === 1) {
        const metaberryState = store.getState().metaberry
        if (true /* metaberryState.areLessonsUpdatedAfterPracticing */) {
          this.destroyLessonPanel()
          this.playerScreenPosition = null
          this.enterPortal()
        }
      }

      /*
      if (this.exitPanel) {
        this.exitPanel.destroy()
        this.exitPanel = null
        this.enterPortal()
      }
      */
      if (this.portalActive.type === 'exit') {
        this.cleanupAndDestroyExitIcon()
        this.enterPortal()
      }
    }
  }

  arePlayerAndPetCreated() {
    return (
      this.player &&
      this.playerPet &&
      this.player.isCharacterDone &&
      this.playerPet.isCharacterDone
    )
  }

  async followPlayer() {
    const playerStandingTile = this.getCharacterStandingTile(this.player)
    const playerPetStandingTile = this.getCharacterStandingTile(this.playerPet)
    const calculatedPath = await this.world.calculateBestPath(
      playerPetStandingTile[0],
      playerPetStandingTile[1],
      playerStandingTile[0],
      playerStandingTile[1]
    )

    if (calculatedPath?.length) {
      calculatedPath.pop()
    }
    this.playerPet.setPath(calculatedPath)
  }

  updatePetFollowPlayer(time, delta, directionsPressed) {
    if (this.arePlayerAndPetCreated()) {
      this.playerPet.update(
        time,
        delta,
        undefined,
        this.player.isMoving(directionsPressed)
      )

      if (
        this.player &&
        this.playerPet &&
        this.arePlayerAndPetCreated() &&
        this.player.isMoving(directionsPressed)
      ) {
        if (!this.petFollowScheduled) {
          this.petFollowScheduled = true
          // Guardar referencia al timeout para limpiarlo si es necesario
          this._petFollowTimeout = setTimeout(async () => {
            if (this.arePlayerAndPetCreated()) {
              this.followPlayer()
            }
            this.petFollowScheduled = false
            this._petFollowTimeout = null
          }, 200)
        }
      } else if (!this.playerPet.isMoving(directionsPressed)) {
        // También guardar referencia a este timeout
        this._petAdjustTimeout = setTimeout(async () => {
          if (this.arePlayerAndPetCreated()) {
            const playerStandingTile = this.getCharacterStandingTile(
              this.player
            )
            const playerPetStandingTile = this.getCharacterStandingTile(
              this.playerPet
            )

            if (
              playerStandingTile[0] === playerPetStandingTile[0] &&
              playerStandingTile[1] === playerPetStandingTile[1]
            ) {
              // La mascota está encima del jugador, moverla a una tile adyacente
              const calculatedPath = await this.world.calculateBestPath(
                playerPetStandingTile[0],
                playerPetStandingTile[1],
                playerPetStandingTile[0] + 1,
                playerPetStandingTile[1] + 1
              )
              this.playerPet.setPath(calculatedPath)
            } else {
              const petPlayerDx =
                playerPetStandingTile[0] - playerStandingTile[0]
              const petPlayerDy =
                playerPetStandingTile[1] - playerStandingTile[1]
              const petDistanceToPlayer = Math.sqrt(
                petPlayerDx * petPlayerDx + petPlayerDy * petPlayerDy
              )

              // La mascota está demasiado lejos del jugador, hacer que lo siga
              if (petDistanceToPlayer > 2) {
                this.followPlayer()
              }
            }
          }
          this._petAdjustTimeout = null
        }, 200)
      }
    }
  }

  async handleMoveCharacterToTile({ detail }) {
    if (detail) {
      try {
        const success = await this.moveCharacterToTile(
          this.player,
          detail.x,
          detail.y
        )
      } catch (error) {
        console.error('Error moving character:', error)
      }
    }
  }

  moveCharacterToTile(character, destinationTileX, destinationTileY) {
    return new Promise((resolve, reject) => {
      // Move async logic inside
      const moveCharacter = async () => {
        try {
          const currentTile = this.getCharacterStandingTile(character)
          const calculatedPath = await this.world.calculateBestPath(
            currentTile[0],
            currentTile[1],
            destinationTileX,
            destinationTileY
          )

          if (!calculatedPath) {
            resolve(false)
            return
          }

          character.setPath(calculatedPath)

          const checkMovement = setInterval(() => {
            const { directionsPressed } = this.getDirectionsPressed()

            if (!character.isMoving(directionsPressed)) {
              clearInterval(checkMovement)
              resolve(true)
            }
          }, 100)
        } catch (error) {
          reject(error)
        }
      }

      moveCharacter()
    })
  }

  getDirectionsPressed() {
    const directionsPressed = { ...this.cursorPressed }
    let joystickForce = null

    if (this.joystickKeys) {
      joystickForce = {
        forceX: this.joystick.forceX,
        forceY: this.joystick.forceY,
        baseWidth: this.joystick.base.width,
        baseHeight: this.joystick.base.height
      }

      directionsPressed.up = directionsPressed.up || this.joystickKeys.up.isDown
      directionsPressed.down =
        directionsPressed.down || this.joystickKeys.down.isDown
      directionsPressed.left =
        directionsPressed.left || this.joystickKeys.left.isDown
      directionsPressed.right =
        directionsPressed.right || this.joystickKeys.right.isDown
    }

    return { directionsPressed, joystickForce }
  }

  updateCharactersMovement(time, delta, directionsPressed, joystickForce) {
    try {
      // Verificar que el jugador existe
      if (!this.player) {
        return
      }

      // Solo actualizar si el personaje está listo
      if (this.player.isCharacterDone) {
        // Actualizar el personaje principal
        this.player.update(time, delta, directionsPressed, joystickForce)

        // Actualizar la mascota solo si existe y está lista
        if (this.playerPet && this.playerPet.isCharacterDone) {
          this.updatePetFollowPlayer(time, delta, directionsPressed)
        }
      }
    } catch (error) {
      console.error('Error en updateCharactersMovement:', error)
      // No propagar el error para evitar que se congele el juego
    }
  }

  // Verificar periódicamente que los inputs y el estado de pausa estén sincronizados
  checkInputsAndPauseState() {
    // Evitar verificar si:
    // - Hay un timeout pendiente de closePortalPanel
    // - Hay popups activos que podrían interferir
    // - Hay paneles activos (unit, lesson, etc.)
    if (
      this._enableInputsTimeout ||
      this.hasPopupToShow() ||
      this.unitPanelWrapper ||
      this.lessonPanelWrapper ||
      this.portalActive
    ) {
      return
    }

    const now = Date.now()
    this._lastInputCheck = this._lastInputCheck || now

    // Solo verificar cada 4 segundos
    if (now - this._lastInputCheck < 4000) return
    this._lastInputCheck = now

    // Obtener el estado actual
    const metaberryState = store.getState().metaberry

    // --------- Verificar estado de inputs ---------
    // Condiciones para que los inputs estén habilitados
    // (tomado de closePortalPanel y enableGame)
    const shouldInputsBeEnabled =
      !metaberryState.isPracticing &&
      !metaberryState.isInQuizz &&
      !metaberryState.isGameKeyboardBlocked &&
      !this.isGameDisabled

    // Variable para rastrear si se corrigieron los inputs
    let inputsWereFixed = false

    // Corregir estado de inputs si es necesario
    if (shouldInputsBeEnabled) {
      if (!this.game.input.enabled) {
        console.log('Scene Guardian: Enable inputs')
        this.game.input.enabled = true
        inputsWereFixed = true
      }

      if (!this.input.keyboard.manager.enabled) {
        console.log('Scene Guardian: Enable keyboard')
        this.setKeyboardState(true)
        inputsWereFixed = true
      }
    }

    // --------- Verificar estado de pausa ---------
    // Condiciones normales para que la escena esté activa (no pausada)
    // (tomado de reduxListener)
    const isKeyboardEnabled =
      !metaberryState.isGameKeyboardBlocked || inputsWereFixed

    const shouldSceneBeActive =
      (!metaberryState.isPracticing &&
        !metaberryState.isInQuizz &&
        !this.isGameDisabled) ||
      (metaberryState.isGameFromPop && isKeyboardEnabled)

    // Corregir estado de pausa si es necesario
    if (shouldSceneBeActive) {
      if (this.scene.isPaused && this.scene.isPaused()) {
        console.log('Scene Guardian: Resume paused scene')
        this.resumeSceneWithSafety(metaberryState.isGameFromPop || false)
      }
    }
  }

  update(time, delta) {
    if (this.createCompleted) {
      const { directionsPressed, joystickForce } = this.getDirectionsPressed()
      this.updateCharactersMovement(
        time,
        delta,
        directionsPressed,
        joystickForce
      )

      // Verificar periódicamente el estado de inputs y pausa
      this.checkInputsAndPauseState()

      // Acumular delta time
      this.accumulatedDelta += delta

      if (this.accumulatedDelta >= UPDATE_THRESHOLD) {
        this.accumulatedDelta = 0

        this.controls.update(delta)

        if (this.player && this.player.isCharacterDone) {
          this.checkPortals(directionsPressed)

          if ((this.unitPanel || this.lessonPanel) && !this.cameraMoving) {
            const mainCamera = this.cameras.main
            const playerPosition = this.getPlayerScreenPosition(mainCamera)

            const batteryPosition =
              this.lessonPanel &&
              this?.portalActive?.batteryPosition &&
              mainCamera
                ? this.getBatteryScreenPosition(
                    this.portalActive.batteryPosition,
                    mainCamera
                  )
                : null

            if (
              this.playerScreenPosition &&
              (this.playerScreenPosition.x !== playerPosition.x ||
                this.playerScreenPosition.y !== playerPosition.y)
            ) {
              // Limpieza explícita del timeout
              if (this.debounceUpdatePanel) {
                clearTimeout(this.debounceUpdatePanel)
                this.debounceUpdatePanel = null
              }

              this.debounceUpdatePanel = setTimeout(() => {
                const playerPositionEvent = new CustomEvent('player-moved', {
                  detail: {
                    playerPosition: playerPosition,
                    batteryPosition: batteryPosition,
                    currentTileSize: Math.round(this.tileSize * mainCamera.zoom)
                  }
                })
                window.dispatchEvent(playerPositionEvent)

                // Limpiar referencia
                this.debounceUpdatePanel = null
              }, 200)
            }
            this.playerScreenPosition = playerPosition
          }
        }
      }
    }

    // El proceso de "fade in" se realiza al finalizar el update y en un 'timemout'
    //  para asegurar que se ha cargado y ajustado todo (mapa, personaje, camara...)
    if (this.loadingJustFinished) {
      window.dispatchEvent(new CustomEvent('loading-finished'))
      ocLog(
        window._getTestTime() + ' - MainScene update loadinjustfinished i tms-'
      )
      increaseCountProgress()

      this.loadingJustFinished = false

      setTimeout(async () => {
        ocLog(
          window._getTestTime() +
            ' - MainScene update loadinjustfinished 1 tms-'
        )
        increaseCountProgress()

        await this.getLockedPortalsPreviousImages()

        this.fadeScene(
          false,
          () => store.dispatch(setIsPhaserVisible(true)),
          () => {
            store.dispatch(setIsGameUIVisible(true))

            // Añade botón de volver a mapa principal
            if (!this.isCourseMap) {
              this.backCourseMapButtonWrapper = document.createElement('div')
              this.backCourseMapButton = createReactElement(
                MapCourseBackButton,
                {
                  handleClick: () => {
                    this.enterPortal(true)
                  }
                }
              )
              this.backCourseMapButtonRoot = createRoot(
                this.backCourseMapButtonWrapper
              )
              this.backCourseMapButtonRoot.render(this.backCourseMapButton)
              document
                .querySelector('body')
                .appendChild(this.backCourseMapButtonWrapper)
            }
            this.isLoading = false
            ocLog(
              window._getTestTime() +
                ' - MainScene update loadinjustfinished e tms-'
            )
            increaseCountProgress(true)

            window.isGameReady = true
            window.dispatchEvent(new CustomEvent('game-started'))
          }
        )
      }, 150)
    }
  }

  destroy() {
    try {
      // TODO: limpiar todo lo que sea necesarrio
      window.removeEventListener('resize', this.handleWindowResize)

      // Limpiar el timer si existe
      if (this.resizeWindowTimer) {
        clearTimeout(this.resizeWindowTimer)
      }

      // Limpiar timeout de actualización de panel
      if (this.debounceUpdatePanel) {
        clearTimeout(this.debounceUpdatePanel)
        this.debounceUpdatePanel = null
      }

      // Limpiar timeout de verificación de inputs
      if (this._enableInputsTimeout) {
        clearTimeout(this._enableInputsTimeout)
        this._enableInputsTimeout = null
      }

      // Limpiar referencias del guardián de inputs
      this._lastInputCheck = null

      // Limpiar timeouts de la mascota
      if (this._petFollowTimeout) {
        clearTimeout(this._petFollowTimeout)
        this._petFollowTimeout = null
      }

      if (this._petAdjustTimeout) {
        clearTimeout(this._petAdjustTimeout)
        this._petAdjustTimeout = null
      }

      // Limpiar componentes React adicionales
      if (this.lessonPanelWrapper) {
        this.destroyLessonPanel()
      }

      if (this.unitPanelWrapper) {
        this.destroyUnitPanel()
      }

      if (this.backCourseMapButtonWrapper) {
        this.destroyBackCourseMapButton()
      }

      // Limpiar referencia a portal activo
      this.portalActive = null

      // Cleanup exit icon
      this.cleanupAndDestroyExitIcon()

      // Ayudar al garbage collector en Safari/iOS
      setTimeout(() => {}, 0)

      // Llamar al destroy original si existe
      super.destroy?.()
    } catch (e) {
      console.warn('Error en destroy:', e)
      try {
        super.destroy?.()
      } catch (error) {
        console.error('Error en super.destroy:', error)
      }
    }
  }
}
