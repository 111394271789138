import React from 'react'
import { useIsMobile } from '../../../../../hooks/useIsMobile'
import './AvatarData.scss'
import DynamicTextContainer from '../../../../atoms/DynamicTextContainer/DynamicTextContainer'
import Button from '../../../../atoms/Button/Button'

const AvatarData = ({
  title,
  description,
  showMainButton,
  textMainButton,
  isIntro,
  handleMainButton
}) => {
  const isMobile = useIsMobile()

  return (
    <>
      <Button
        text={textMainButton}
        onClick={handleMainButton}
        isIntro={isIntro}
        testId="avatar_data_button"
      />
      <div className="avatar-data-container">
        {isMobile && isIntro && <div className="avatar-data__decoration"></div>}

        <div className="avatar-data-container__title">{title}</div>

        {showMainButton && (!isMobile || !isIntro)}

        <DynamicTextContainer titleText={description} />
      </div>
    </>
  )
}

export default AvatarData
