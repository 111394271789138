import { createSlice } from '@reduxjs/toolkit'
import { createUnits } from './helpers/createUnits'
import { findUnitIndex, findLessonIndex } from './helpers/findItemIndex'
import updateLessons from './helpers/updateLessons'

import extractDefaultLessonData from './helpers/extractDefaultLessonData'

const initialState = []

export const UnitsSlice = createSlice({
  name: 'Units',
  initialState,
  reducers: {
    initUnits: (state, { payload }) => {
      if (payload?.learningMapData?.length) {
        return createUnits(payload)
      }
    },

    initLessons: (state, { payload }) => {
      const { unit_guid, lessons } = payload
      const currentUnitIdx = findUnitIndex(unit_guid, state)
      state[currentUnitIdx].lessons = lessons.map((item) =>
        extractDefaultLessonData(item)
      )
    },

    updateLesson: (state, { payload }) => {
      const { unit_guid, lesson } = payload
      const currentUnitIdx = findUnitIndex(unit_guid, state)
      const currentLessonIdx = findLessonIndex(
        lesson.lesson_guid,
        state[currentUnitIdx].lessons
      )

      state[currentUnitIdx].lessons[currentLessonIdx] = {
        ...state[currentUnitIdx].lessons[currentLessonIdx],
        ...lesson
      }

      return state
    },

    updateLessonStatus: (state, { payload }) => {
      const { unit_guid, lesson } = payload
      const currentUnitIdx = findUnitIndex(unit_guid, state)
      const currentLessonIdx = findLessonIndex(
        lesson.lesson_guid,
        state[currentUnitIdx].lessons
      )

      state[currentUnitIdx].lessons[currentLessonIdx].status = lesson.status

      return state
    },

    updateUnitsAndLessons: (state, { payload }) => {
      const { unit_guid, units, lessons } = payload

      const unitIdx = findUnitIndex(unit_guid, units)
      units[unitIdx].lessons = lessons.map((item) =>
        extractDefaultLessonData(item)
      )

      return units
    },

    updateUnit: (state, { payload }) => {
      const { unit_guid, lessons } = payload
      const currentUnitIdx = findUnitIndex(unit_guid, state)
      state[currentUnitIdx].lessons = updateLessons(
        lessons,
        state[currentUnitIdx]
      )
    },

    hydrateUnits: (state, { payload }) => {
      return payload
    },

    changeLessonsStatus: (state, { payload }) =>
      state.forEach((units) =>
        units.lessons.forEach((lesson) => {
          lesson.status = payload
        })
      ),

    resetUnits: () => initialState
  }
})

export const {
  initUnits,
  initLessons,
  updateLesson,
  updateLessonStatus,
  updateUnitsAndLessons,
  updateUnit,
  hydrateUnits,
  changeLessonsStatus,
  resetUnits
} = UnitsSlice.actions

export default UnitsSlice.reducer
