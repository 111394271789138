import { createEndPoint } from './util'
import { clearToken, getToken } from './util/token'
import { Analysis } from './analysisService'
import { getLoggedInViaToken } from './settingsService'
import { clearQAMode } from './qaModeService'
import {
  setUser as setUserSentry,
  setContext as setContextSentry,
  setTag as setTagSentry
} from '@sentry/core'

export async function logout() {
  const userToken = getToken()

  if (userToken) {
    // Para desde MainScene actualizar los datos de respawn
    const logoutEventPromise = new Promise((resolve) => {
      window.dispatchEvent(
        new CustomEvent('user-logout', { detail: { promiseResolve: resolve } })
      )
    })

    const wasGameInitialized = window.localStorage.getItem(
      'bb_game_initialized'
    )
    if (wasGameInitialized) {
      await logoutEventPromise
    }

    fetch(createEndPoint('logout'), {
      method: 'POST',
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const isLemonadePreview =
        window.location.pathname.includes('__lemonade-preview')
      if (window.isProductionEnvironment && !isLemonadePreview) {
        setUserSentry(null)
        setContextSentry(null)
        setTagSentry('course_guid', null)
        setTagSentry('education_level_name', null)
        setTagSentry('education_year_name', null)
        setTagSentry('education_discipline_name', null)
      }

      if (res.status === 200) {
        const loggedInViaToken = getLoggedInViaToken()
        Analysis.sendEvent(Analysis.EVENT.LOGOUT)
        Analysis.clearUser()
        clearQAMode()
        console.log('Está en logoutService clearToken')
        clearToken()

        if (loggedInViaToken && window.opener !== null) {
          window.close()
        } else {
          redirectToLogin()
        }
      }

      if (res.status === 403) {
        redirectToLogin()
      }

      return res.json()
    })
  }
}

function redirectToLogin() {
  window.location.href = `${window.location.protocol}//${window.location.host}/`
}
