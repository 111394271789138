import React from 'react'
import './textContainer.scss'

import { ReactComponent as AudioButton } from '../../../assets/img/metaberry_ui/sound_on.svg'

function TextContainer({ titleText, audioFile, playSoundEffect }) {
  return (
    <div className="text-container-component">
      <div className="text-container-component_content">
        <div className="text-container-component_message">{titleText}</div>
        {audioFile && (
          <AudioButton
            className="text-container-component_audio-button"
            onClick={() => {
              playSoundEffect(audioFile)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default TextContainer
