import React from 'react'
import { ReactComponent as GreenCheck } from '../../../../../../../assets/img/check-circle.svg'
import { ReactComponent as CheckCircle } from '../../../../../../../assets/img/icon-grey.svg'
import { useIsMobile } from '../../../../../../../hooks/useIsMobile'

import './StreakProgress.scss'
import PropTypes from 'prop-types'

const StreakProgress = ({ currentStreak }) => {
  const auxStreak = currentStreak > 4 ? 4 : currentStreak
  const isMobile = useIsMobile()

  const renderBullets = () => {
    if (isMobile) {
      const bullets = []
      let firstBullet = true
      const mobileStreak = currentStreak > 3 ? 3 : currentStreak
      const startIndex = mobileStreak === 1 ? 0 : mobileStreak - 2

      for (let i = startIndex; i < startIndex + 3; i++) {
        if (i >= 0 && i <= 3) {
          bullets.push(
            <React.Fragment key={i}>
              {!firstBullet && (
                <div
                  className={`streak-progress__connector ${
                    currentStreak > i && i !== startIndex + 2
                      ? 'streak-progress__connector--on'
                      : 'streak-progress__connector--off'
                  }`}
                ></div>
              )}
              <div className="streak-progress__bullet">
                {i < mobileStreak ? <GreenCheck /> : <CheckCircle />}
              </div>
            </React.Fragment>
          )
          firstBullet = false
        }
      }
      return bullets
    }

    return (
      <>
        <div className="streak-progress__bullet">
          {auxStreak - 1 >= 0 ? <GreenCheck /> : <CheckCircle />}
        </div>
        <div
          className={`streak-progress__connector ${
            auxStreak > 1
              ? 'streak-progress__connector--on'
              : 'streak-progress__connector--off'
          }`}
        ></div>
        <div className="streak-progress__bullet">
          {auxStreak - 1 >= 1 ? <GreenCheck /> : <CheckCircle />}
        </div>
        <div
          className={`streak-progress__connector ${
            auxStreak > 2
              ? 'streak-progress__connector--on'
              : 'streak-progress__connector--off'
          }`}
        ></div>
        <div className="streak-progress__bullet">
          {auxStreak - 1 >= 2 ? <GreenCheck /> : <CheckCircle />}
        </div>
        <div
          className={`streak-progress__connector ${
            auxStreak > 3
              ? 'streak-progress__connector--on'
              : 'streak-progress__connector--off'
          }`}
        ></div>
        <div className="streak-progress__bullet">
          {auxStreak - 1 >= 3 ? <GreenCheck /> : <CheckCircle />}
        </div>
        <div
          className={`streak-progress__connector ${
            auxStreak > 4
              ? 'streak-progress__connector--on'
              : 'streak-progress__connector--off'
          }`}
        ></div>
        <div className="streak-progress__bullet">
          {auxStreak - 1 >= 4 ? <GreenCheck /> : <CheckCircle />}
        </div>
      </>
    )
  }

  return <div className="streak-progress">{renderBullets()}</div>
}

StreakProgress.propTypes = {
  currentStreak: PropTypes.number.isRequired
}

export default StreakProgress
