import React, { useEffect, useState } from 'react'
import CrownImg from '../../../../../assets/img/metaberry_ui/crown_new.svg'
import translate from '../../../../../i18n/translate'
import { getUserAvatar } from '../../../../../services/settingsService'

const MetaRankingItem = ({
  isCurrentUser,
  rank,
  fullName,
  experience,
  avatarImg,
  pathSchool
}) => {
  const [avatarImageUrl, setAvatarImageUrl] = useState(null)
  const [avatarSize] = useState('regular')

  useEffect(() => {
    let auxAvatarImageUrl =
      avatarImg ||
      (pathSchool && pathSchool !== '' && pathSchool.includes('human_cartoons')
        ? 'https://tangerine-prod-content.s3.eu-central-1.amazonaws.com/AV-24/AV-24_single.png'
        : 'https://tangerine-prod-content.s3.eu-central-1.amazonaws.com/AV-06/AV-06_single.png')

    if (isCurrentUser) {
      auxAvatarImageUrl = getUserAvatar().single_image
      window.addEventListener('player-avatar-change', updateCurrentUserAvatar)
    }

    setAvatarImageUrl(auxAvatarImageUrl)

    return () => {
      if (isCurrentUser) {
        window.removeEventListener(
          'player-avatar-change',
          updateCurrentUserAvatar
        )
      }
    }
  }, [])

  const updateCurrentUserAvatar = () => {
    setAvatarImageUrl(getUserAvatar().single_image)
  }

  const BgGradient = () => (
    <div className="avatar-icon__bg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="35"
        viewBox="0 0 52 35"
        fill="none"
      >
        <ellipse
          cx="26.2605"
          cy="17.3242"
          rx="25.375"
          ry="17.2187"
          fill="url(#paint0_linear_4015_6408)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4015_6408"
            x1="26.2605"
            y1="0.105469"
            x2="26.2605"
            y2="34.543"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#C5C5C5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )

  return (
    <div
      className={`meta-ranking-item${
        isCurrentUser ? ' meta-ranking-item--selected' : ''
      }`}
    >
      <div className="meta-ranking-item__icon">
        {rank === 1 && <img src={CrownImg} alt="" />}
      </div>
      <div className="meta-ranking-item__position">{rank}</div>
      <div
        className="meta-ranking-item__name"
        title={isCurrentUser ? translate('unit_ranking_you') : fullName}
      >
        {isCurrentUser ? translate('unit_ranking_you') : fullName}
      </div>
      <div className={`meta-ranking-item__experience`}>
        <div className="meta-ranking-item__avatars-button">
          <div className="avatar-icon">
            <BgGradient />
            <div
              className={`avatar-icon__image avatar-icon__image--${avatarSize}`}
              style={{
                backgroundImage: `url(${avatarImageUrl})`
              }}
            ></div>
          </div>
        </div>
        {experience} EXP
      </div>
    </div>
  )
}

export default MetaRankingItem
