import React, { useEffect, useRef, useState } from 'react'
import { LemonadeRenderContext } from '@oneclick/react-lemonade-editor'
import { ACTIVITY_TYPES } from '../../../constants/ActivityConstants'
import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'
import ExerciseAudioButton from '../ExerciseAudioButton/ExerciseAudioButton'
import './feedbackBox.scss'
import { FEEDBACK_TYPE } from '../../../constants/FeedbackConstants'

const FeedbackBox = ({
  feedbackType,
  content,
  onActivityEvent,
  isVisible,
  hideTitle,

  /* Para la asistencia auditiva */
  panelInformationType,
  exerciseGuid,
  requiresAudioAssistance,
  getTextAudio,
  pathAssetsSchool
}) => {
  const { activityType } = useActivityContext()
  // Establecer tipo e icono para el panel de feedback
  // content = '<p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p>'
  const feedbackReference = useRef()

  const boxRef = useRef()
  const [type] = useState(feedbackType.toLowerCase())
  const [audioText] = useState(content?.message || '')

  const [blueberro_image] = useState(
    `${pathAssetsSchool}/${
      feedbackType === FEEDBACK_TYPE.HINT
        ? 'blueberro_hint.svg'
        : `${type}_${content.variant}.svg`
    }`
  )

  useEffect(() => {
    boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [])

  return (
    <div className="feedback-box">
      <div className={'feedback-box__box type-' + type} ref={boxRef}>
        {content.message && (
          <div className="feedback-box__content">
            {activityType !== ACTIVITY_TYPES.QUIZ && (
              <div className="feedback-box__content-audio">
                <ExerciseAudioButton
                  requiresSoundAssistance={requiresAudioAssistance}
                  exerciseGuid={exerciseGuid}
                  isVisible={true}
                  eventAudioType={panelInformationType}
                  getAudio={(signal) => getTextAudio(audioText, signal)}
                  onActivityEvent={onActivityEvent}
                />
              </div>
            )}
            <LemonadeRenderContext>
              <div
                ref={feedbackReference}
                className="lemonade-feedback-message"
                dangerouslySetInnerHTML={{ __html: content.message }}
              ></div>
            </LemonadeRenderContext>
          </div>
        )}
        {type === 'hint' && (
          <div className={'feedback-box__blueberro-' + type}>
            <img
              className="feedback-box__blueberro-image"
              src={blueberro_image}
              alt={'Blueberro' + type}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedbackBox
