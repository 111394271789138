import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import OnboardingIntroduction from './components/OnboardingIntroduction/OnboardingIntroduction'
import OnboardingBatteryComplete from './components/OnboardingBatteryComplete/OnboardingBatteryComplete'
import OnboardingBatteryCharged from './components/OnboardingBatteryCharged/OnboardingBatteryCharged'
import OnboardingFirstPiece from './components/OnboardingFirstPiece/OnboardingFirstPiece'
import OnboardingUnlockedLesson from './components/OnboardingUnlockedLesson/OnboardingUnlockedLesson'
import OnboardingUnlockedUnit from './components/OnboardingUnlockedUnit/OnboardingUnlockedUnit'
import OnboardingFirstUserLevel from './components/OnboardingFirstUserLevel/OnboardingFirstUserLevel'
import RewardDailyGoal from './components/RewardDailyGoal/RewardDailyGoal'
import RewardBatteryUnlock from './components/RewardBatteryUnlock/RewardBatteryUnlock'

import usePopupManager from './usePopupManager'
import InformationPopup from '../../atoms/InformationPopup/InformationPopup'
import translate from '../../../i18n/translate'
import OnboardingFirstMovesPanel1 from './components/OnboardingFirstMoves/OnboardingFirstMovesPanel1'
import { getCurrentLangString } from '../../../i18n/initI18n'

const PopupManager = ({ handleFinishIntro, handleGoToPetsSelector }) => {
  const {
    hasPopup,
    getFirstPopup,
    checkPopup,
    pushPopup,
    resetOnboarding,
    hasOnboarding,
    handleCheckIntroduction,
    pushOnboardingPanel,
    handleCheckOnboardingPanel,
    showIntroduction,
    pathSchool
  } = usePopupManager()

  const isDevMode = useSelector((state) => state.configuration.isDevMode)
  const units = useSelector((state) => state.units || [])
  const unitsImages = useSelector(
    (state) => state.course.courseUnitsImages || []
  )

  const firstUnit =
    units.length > 0 && unitsImages.length > 0
      ? { ...units[0], image: unitsImages[0].image }
      : null

  const [popupData, setPopupData] = useState({})

  const popupComponent = {
    onboardingFirstMoves: hasOnboarding() ? (
      <OnboardingFirstMovesPanel1
        handleCheck={() => handleCheckOnboardingPanel('firstMoves')}
        pathSchool={pathSchool}
        unit={firstUnit}
      />
    ) : null,

    onboardingFirstPiece: hasOnboarding() ? (
      <OnboardingFirstPiece
        handleCheck={() => handleCheckOnboardingPanel('firstPiece')}
        pathSchool={pathSchool}
      />
    ) : null,

    onboardingLessonUnlocked: hasOnboarding() ? (
      <OnboardingUnlockedLesson
        handleCheck={() => handleCheckOnboardingPanel('firstUnlockedLesson')}
        pathSchool={pathSchool}
      />
    ) : null,

    onboardingUnitUnlocked: hasOnboarding() ? (
      <OnboardingUnlockedUnit
        handleCheck={() => handleCheckOnboardingPanel('firstUnlockedUnit')}
      />
    ) : null,

    onboardingFirstUserLevel: hasOnboarding() ? (
      <OnboardingFirstUserLevel
        handleCheck={() => handleCheckOnboardingPanel('firstUserLevel')}
        pathSchool={pathSchool}
      />
    ) : null,

    onboardingCompleteBattery: hasOnboarding() ? (
      <OnboardingBatteryComplete
        handleCheck={() => {
          handleCheckOnboardingPanel('firstBateryComplete')
        }}
        pathSchool={pathSchool}
      />
    ) : null,

    onboardingChargedBattery: hasOnboarding() ? (
      <OnboardingBatteryCharged
        handleCheck={() => handleCheckOnboardingPanel('firstBateryCharged')}
        pathSchool={pathSchool}
      />
    ) : null,

    rewardDailyGoal: <RewardDailyGoal handleCheck={checkPopup} />,

    rewardBatteryUnlock: <RewardBatteryUnlock handleCheck={checkPopup} />,

    userLevelUp: (
      <InformationPopup
        className="information-popup__level-up"
        message={translate('user_level_up')}
        level={popupData.userLevel}
        closeAction={checkPopup}
        showCloseIcon={true}
        audioFile={'levelUpPopup_' + getCurrentLangString()}
      />
    )
  }

  useEffect(() => {
    const addListener = window.addEventListener
    addListener('onboarding-lesson-unlocked', handleLessonUnlocked)
    addListener('onboarding-charged-battery', handleChargedBattery)
    addListener('onboarding-complete-battery', handleCompleteBattery)
    addListener('onboarding-unit-unlocked', handleUnitUnlocked)
    addListener('onboarding-first-user-level', handleFirstLevelUser)
    addListener('onboarding-first-piece', handleFirstPiece)
    addListener('reward-daily-goal', handleRewardDailyGoal)
    addListener('reward-battery-unlock', handleRewardBatteryUnlock)
    addListener('user-level-up', handleUserLevelUp)

    return () => {
      const removeListener = window.removeEventListener
      removeListener('onboarding-lesson-unlocked', handleLessonUnlocked)
      removeListener('onboarding-charged-battery', handleChargedBattery)
      removeListener('onboarding-complete-battery', handleCompleteBattery)
      removeListener('onboarding-unit-unlocked', handleUnitUnlocked)
      removeListener('onboarding-first-user-level', handleFirstLevelUser)
      removeListener('onboarding-first-piece', handleFirstPiece)
      removeListener('reward-daily-goal', handleRewardDailyGoal)
      removeListener('reward-battery-unlock', handleRewardBatteryUnlock)
      removeListener('user-level-up', handleUserLevelUp)
    }
  }, [])

  const handleLessonUnlocked = () =>
    pushOnboardingPanel('firstUnlockedLesson', 'onboardingLessonUnlocked')

  const handleChargedBattery = () =>
    pushOnboardingPanel('firstBateryCharged', 'onboardingChargedBattery')

  const handleCompleteBattery = () =>
    pushOnboardingPanel('firstBateryComplete', 'onboardingCompleteBattery')

  const handleUnitUnlocked = () =>
    pushOnboardingPanel('firstUnlockedUnit', 'onboardingUnitUnlocked')

  const handleFirstLevelUser = () =>
    pushOnboardingPanel('firstUserLevel', 'onboardingFirstUserLevel')

  const handleFirstPiece = () =>
    pushOnboardingPanel('firstPiece', 'onboardingFirstPiece')

  const handleRewardDailyGoal = () => pushPopup('rewardDailyGoal')

  const handleRewardBatteryUnlock = () => pushPopup('rewardBatteryUnlock')

  const handleUserLevelUp = (event) => {
    setPopupData((prevState) => ({
      ...prevState,
      userLevel: event.detail.level
    }))
    pushPopup('userLevelUp')
  }

  return (
    <>
      {!!isDevMode && (
        <button
          className="onboarding__debug"
          onClick={resetOnboarding}
          data-testid="onboarding_debug"
        />
      )}

      {hasPopup() || showIntroduction() ? (
        <div className="popup-manager">
          {hasPopup() ? (
            popupComponent[getFirstPopup()]
          ) : (
            <OnboardingIntroduction
              handleCheck={() => {
                handleCheckIntroduction()
                handleFinishIntro()
              }}
              pathSchool={pathSchool}
            />
          )}
        </div>
      ) : null}
    </>
  )
}

PopupManager.propTypes = {
  handleFinishIntro: PropTypes.func.isRequired,
  handleGoToPetsSelector: PropTypes.func.isRequired
}

export default PopupManager
