export default function createEndPoint(endPoint) {
  const path = endPoint ? `/${endPoint}` : ''
  const { origin } = window.location

  //*
  return origin.match(/http:\/\/localhost:/)
    ? window.bbDevApiURL + path
    : `${origin}/api` + path
  // */
  /* Para browserStack web con iphone/ipad usar esto y comentar lo anterior
  return origin.match(/http:\/\/bs-local.com:/)
    ? window.bbDevApiURL + path
    : `${origin}/api` + path
  // */
}
