import { createEndPoint, getToken } from './util'
import {
  getAppHostReactNative,
  getAppTokenReactNative
} from '../pages/AppPracticePage/services/practiceServicesReactNative'

const { fetch } = window

export async function getAudio(
  text,
  questionGuid,
  language,
  signal,
  isFromApp
) {
  let fetchEndPoint = !isFromApp
    ? createEndPoint('blueberry/text-to-speech')
    : getAppHostReactNative() + '/app/blueberry/text-to-speech'

  if (text) {
    const auxDiv = document.createElement('div')
    auxDiv.innerHTML = text
    const cleanText = auxDiv.innerText.trim()
    auxDiv.remove()

    fetchEndPoint += `?text=${encodeURIComponent(
      cleanText
    )}&lang=${language.toLowerCase()}`
  } else if (questionGuid) {
    fetchEndPoint += `/question/${questionGuid}/lang/${language.toLowerCase()}`
  }

  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: !isFromApp
        ? getToken()
        : `Bearer ${getAppTokenReactNative()}`,
      'Content-Type': 'audio/mpeg'
    },
    referrerPolicy: 'no-referrer',
    signal
  }

  try {
    const response = await fetch(fetchEndPoint, fetchOptions)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const audio = new Audio()
    audio.src = url

    return audio
  } catch (error) {
    if (error.name === 'AbortError') {
      // Propagamos el AbortError sin logging ya que es un caso esperado
      throw error
    }
    console.error('Audio Button Error:', error)
    throw error
  }
}
