import React from 'react'
import './quizPodium.scss'
import RankingStar from '../../assets/quizz/ranking_star.svg'
// import WinnerBackground from '../../assets/quizz/winner_background.svg'
import Bubble from '../../assets/quizz/bubble.png'
import fullscreen from '../../assets/fullscreen.svg'
import fullscreenExit from '../../assets/fullscreen-exit.svg'
import usePodium from './usePodium'

const QuizPodium = ({
  playerGuid,
  playerList,
  questionsTotal,
  questionsRight,
  exitQuizz,
  translate,
  goFullScreen,
  exitFullScreen,
  isFullscreen,
  analysis,
  quizGuid
}) => {
  const { position, userPodium, points, playerAvatarUrl } = usePodium(
    playerGuid,
    playerList,
    analysis,
    quizGuid,
    questionsRight
  )

  return (
    <div className="quiz-podium">
      <div className="container-fullscreen">
        <div className="container-fullscreen-child">
          <button
            className="container-fullscreen-button"
            onClick={() => {
              isFullscreen ? exitFullScreen() : goFullScreen()
            }}
          >
            <img
              src={isFullscreen ? fullscreenExit : fullscreen}
              style={{ width: '16px', height: '16px', color: 'white' }}
            />
          </button>
        </div>
      </div>

      <div className="quiz-podium__wrapper">
        <div className="quiz-podium__title">
          {translate('teacher_practice_podium_title')}
        </div>

        <div className="quiz-podium__body">
          <div className="quiz-podium__podium_star">
            <img src={RankingStar} alt="podium_star" />
            <span>{position}</span>
          </div>

          <div className="quiz-podium__content">
            <div className="quiz-podium__mount_wrapper">
              <div className="podium_avatar_wrapper">
                <img
                  className="podium_avatar"
                  src={playerAvatarUrl}
                  alt="podium_avatar"
                />
                <img
                  className="podium_bubble"
                  src={Bubble}
                  alt="podium_bubble"
                />
              </div>
              <img
                className="podium_mount"
                src={userPodium}
                alt="podium_mount"
              />
            </div>

            <div className="quiz-podium__results">
              <div className="quiz-podium__results_points">
                <div className="quiz-podium__results_points_text">
                  {translate('teacher_practice_podium_points_title')}
                </div>

                <div className="quiz-podium__results_points_user_points">
                  {translate('teacher_practice_podium_points_message', {
                    points: points
                  })}
                </div>
              </div>
              <div className="quiz-podium__results_questions">
                <div className="quiz-podium__results_questions_text">
                  {translate('teacher_practice_podium_answers_title')}
                </div>
                <div className="quiz-podium__results_questions_user_questions">
                  {translate('teacher_practice_podium_answers_message', {
                    correctAnswers: questionsRight,
                    totalQuestions: questionsTotal
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="quiz-podium__button_exit" onClick={exitQuizz}>
          {translate('teacher_practice_podium_exit_button')}
        </div>
      </div>
    </div>
  )
}

export default QuizPodium
