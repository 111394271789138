import React, { useEffect, useState } from 'react'
import translate from '../../i18n/translate'
import spacialCraft from './assets/spacialCraft.svg'
import usersIcon from './assets/usersIcon.svg'
import fullscreen from './assets/fullscreen.svg'
import fullscreenExit from './assets/fullscreen-exit.svg'
import useLobbyTeacherPracticePage from './useLobbyTeacherPracticePage'
import { useSelector } from 'react-redux'
import PlayerWaitingTeacherPractice from './PlayerWaitingTeacherPractice/PlayerWaitingTeacherPractice'
import TextContainer from '../../modules/atoms/TextContainer/TextContainer'
import audioWaitingEs from '../../assets/sound/quiz/es_waiting-for-players.mp3'
import audioWaitingEn from '../../assets/sound/quiz/en_waiting-for-players.mp3'
import audioWaitingPt from '../../assets/sound/quiz/pt_waiting-for-players.mp3'
import audioAllSetEs from '../../assets/sound/quiz/es_all-set.mp3'
import audioAllSetEn from '../../assets/sound/quiz/en_all-set.mp3'
import audioAllSetPt from '../../assets/sound/quiz/pt_all-set.mp3'
import { getCurrentLangString } from '../../i18n/initI18n'
import { ExerciseAudioManager } from '../../_practice-components/utils/exerciseAudioManager'
import { getSoundAssistance } from '../../services/settingsService'

const LobbyTeacherPracticePage = ({
  showPage = false,
  totalPlayers = 0,
  conectedPlayers = 0,
  players = [],
  countDownTeacherPractice = 0,
  keyCountDown,
  goFullScreen,
  exitFullScreen,
  isFullscreen
}) => {
  const AUDIO_FILES = {
    es: [audioWaitingEs, audioAllSetEs],
    en: [audioWaitingEn, audioAllSetEn],
    pt: [audioWaitingPt, audioAllSetPt]
  }

  const {
    playersWithParsedSettings,
    allPlayersOnline,
    messageLobby,
    windowSize,
    NUMBER_PLAYERS_FULL
  } = useLobbyTeacherPracticePage(
    players,
    conectedPlayers,
    totalPlayers,
    translate
  )

  const [pathSchool] = useState(
    useSelector((state) => state.configuration.pathSchool)
  )
  const [defaultAvatarImage, setDefaultAvatarImage] = useState(null)
  const [audioWaiting, setAudioWaiting] = useState(null)
  const [audioAllSet, setAudioAllSet] = useState(null)
  const [audioId, setAudioId] = useState(null)

  useEffect(() => {
    setDefaultAvatarImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/default_single_avatar.png`
        : `/assets/graphics/gamification/default_single_avatar.png`
    )

    const langCode = getCurrentLangString()
    setAudioWaiting(AUDIO_FILES[langCode][0])
    setAudioAllSet(AUDIO_FILES[langCode][1])

    if (getSoundAssistance()) {
      const audio = new Audio(AUDIO_FILES[langCode][0])
      const newAudioId = ExerciseAudioManager.addAudio(audio)
      setAudioId(newAudioId)
      ExerciseAudioManager.playAudio(newAudioId)
    }

    return () => {
      ExerciseAudioManager.removeAudio(audioId)
    }
  }, [])

  const handleManualPlayAudio = () => {
    if (audioId) {
      ExerciseAudioManager.removeAudio(audioId)
    }

    const audio = new Audio(!allPlayersOnline ? audioWaiting : audioAllSet)
    const newAudioId = ExerciseAudioManager.addAudio(audio)
    setAudioId(newAudioId)
    ExerciseAudioManager.playAudio(newAudioId)
  }

  return (
    showPage && (
      <div className="lobby-teacher-practice-page">
        <div className="container-fullscreen">
          <div className="container-fullscreen-child">
            <button
              className="container-fullscreen-button"
              onClick={() => {
                isFullscreen ? exitFullScreen() : goFullScreen()
              }}
            >
              <img
                src={isFullscreen ? fullscreenExit : fullscreen}
                style={{ width: '16px', height: '16px', color: 'white' }}
              />
            </button>
          </div>
        </div>
        {countDownTeacherPractice === null && (
          <>
            <div
              className={`${
                players && players.length > 0
                  ? 'main-container'
                  : 'main-container center'
              }`}
            >
              {!players ||
                (players.length === 0 && (
                  <div className="">
                    <img src={spacialCraft} alt="" />
                  </div>
                ))}
              {players && players.length > 0 && (
                <>
                  <div className="container-block-number-players">
                    <TextContainer
                      titleText={
                        allPlayersOnline
                          ? translate('teacher_practice_lobby_ready')
                          : translate('teacher_practice_lobby_waiting_players')
                      }
                      audioFile={!allPlayersOnline ? audioWaiting : audioAllSet}
                      playSoundEffect={handleManualPlayAudio}
                    />

                    <div className="container-number-players">
                      <img src={usersIcon} alt="" />
                      <div className="container-number-players-child">
                        {conectedPlayers}{' '}
                        {translate('teacher_practice_lobby_of')} {totalPlayers}{' '}
                        {translate('teacher_practice_lobby_jugadores')}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="container-content">
                {playersWithParsedSettings &&
                  playersWithParsedSettings.length > 0 && (
                    <>
                      <div className="players-container">
                        {playersWithParsedSettings
                          .sort(
                            (a, b) =>
                              (b.is_online === true ? 1 : -1) -
                              (a.is_online === true ? 1 : -1)
                          )
                          .map((player, index) => (
                            <PlayerWaitingTeacherPractice
                              player={player}
                              key={index}
                              defaultAvatarImage={defaultAvatarImage}
                              playersWithParsedSettings={
                                playersWithParsedSettings
                              }
                              NUMBER_PLAYERS_FULL={NUMBER_PLAYERS_FULL}
                              windowSize={windowSize}
                            />
                          ))}
                      </div>
                    </>
                  )}
                <div className="message-container">
                  <span className="text">{messageLobby}</span>
                </div>
              </div>
            </div>
          </>
        )}

        {countDownTeacherPractice !== 0 && (
          <div className="container-countdown">
            <div className="text-countdown" key={keyCountDown}>
              {countDownTeacherPractice}
            </div>
          </div>
        )}
        {true && (
          <div className="container-countdown">
            <div className="text-countdown-end">
              {translate('teacher_practice_lobby_go')}
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default LobbyTeacherPracticePage
