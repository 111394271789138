import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Analysis } from '../../../services/analysisService'
import {
  savePetAvatar,
  getPetAvatar,
  updateAccumulatedCoins,
  buyPetAvatarGamificationEngine,
  getPetAvatarsList
} from '../../../services/rewardsService'
import { getCurrentLangString } from '../../../i18n/initI18n'
import { store } from '../../../store/configureStore'

function useMetaTabPets() {
  const userCurrentLevel = useSelector((s) => s.userExperience.level)
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)

  const [petAvatarList, setPetAvatarList] = useState([])
  const [petAvatarSelected, setPetAvatarSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [showBuyErrorPopup, setShowBuyErrorPopup] = useState(false)
  const [showToast, setShowToast] = useState(false)

  async function getData() {
    setIsLoading(true)

    // Full list of pets
    try {
      const apiPetsData = await getPetAvatarsList()
      if (apiPetsData) {
        // Change this line to use the API data
        setPetAvatarList(apiPetsData)

        // Current pet avatar
        setPetAvatarSelected(getPetAvatar())
      }
    } catch (error) {
      console.error(
        'Error al obtener los datos de los avatares de mascotas',
        error
      )
    }
    setIsLoading(false)
  }

  const translateDescription = (description) => {
    const langCode = getCurrentLangString()
    try {
      let descriptionObject
      // Verificar si la descripción es una cadena para parsearla.
      if (typeof description === 'string') {
        try {
          descriptionObject = JSON.parse(description)

          // En caso de error al parsear, se maneja la excepción.
          // Puedes decidir qué hacer en este caso, aqui simplemente se imprime un mensaje de error en la consola.
        } catch (error) {
          console.error('Error al parsear el string: ', error)
        }
      } else if (typeof description === 'object') {
        // Si es un objeto, no es necesario parsearlo.
        descriptionObject = description
      }
      return descriptionObject[langCode]
    } catch (error) {
      return description
    }
  }

  const sortedPetAvatarList = petAvatarList
    .map((item) => {
      const _metadata = item.metadata ? JSON.parse(item.metadata) : null
      return {
        guid: item.id,
        animation: _metadata,
        petLevel: item.requiredLevel?.order,
        isLocked: item.requiredLevel?.order > userCurrentLevel,
        isOwned: item.owned,
        isExpensive: item.price > coinsAccumulated,
        isFree: item.price <= 0,
        price: item.price,
        name: item.name,
        description: translateDescription(item.description),
        singleImage: item.imageUrl,
        navigationIconImage: item.navigation_icon_image,
        card_image: _metadata?.cardImage, // item.card_image,
        card_image_svg: item.card_image_svg
      }
    })
    .sort((a, b) => {
      return a.price - b.price
    })
    .sort((a, b) => {
      return a.petLevel - b.petLevel
    })

  const handleSelectPetAvatar = (guid) => {
    const selectedPetAvatarData = petAvatarList.find((item) => {
      return item.id === guid
    })

    let currentPetAvatar = {}

    if (guid === null) {
      currentPetAvatar = null

      Analysis.sendEvent(Analysis.EVENT.REMOVE_PET)

      Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Pet Removed'])
    } else {
      currentPetAvatar = {
        guid: selectedPetAvatarData.id,
        name: selectedPetAvatarData.name,
        animation: selectedPetAvatarData.metadata
          ? JSON.parse(selectedPetAvatarData.metadata)
          : null
      }

      const currentState = store.getState()
      const petEventObject = {
        guid: currentPetAvatar.guid,
        name: currentPetAvatar.name,
        current_level: currentState.userExperience.level,
        current_points: currentState.rewards.coinsAccumulated
      }

      Analysis.sendEvent(Analysis.EVENT.SELECT_PET)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Pet Popup Button Clicked'],
        petEventObject
      )
    }

    savePetAvatar(currentPetAvatar)
    setPetAvatarSelected(currentPetAvatar)

    // Show confirmation toast
    setShowToast(true)
    setTimeout(() => setShowToast(false), 3000)

    window.dispatchEvent(new CustomEvent('pet-avatar-change'))
  }

  const handleBuyPetAvatar = async (guid) => {
    const buyPetResult = await buyPetAvatarGamificationEngine(guid)

    if (buyPetResult?.status === 'success') {
      // Si no tenemos avatar seleccionado, seleccionamos el que acabamos de comprar
      const currentPetAvatar = getPetAvatar()

      if (!currentPetAvatar || currentPetAvatar.guid !== guid)
        handleSelectPetAvatar(guid)

      getData()

      // Actualizar monedas acumuladas
      updateAccumulatedCoins()
    } else {
      setShowBuyErrorPopup(true)
      console.error('Error buyin pet', buyPetResult)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    handleSelectPetAvatar,
    handleBuyPetAvatar,
    sortedPetAvatarList,
    petAvatarSelected,
    userCurrentLevel,
    coinsAccumulated,
    isLoading,
    showBuyErrorPopup,
    setShowBuyErrorPopup,
    showToast,
    setShowToast
  }
}

export default useMetaTabPets
