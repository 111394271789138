import React from 'react'
import translate from '../../../i18n/translate'
import MetaOptionsMenuToast from '../MetaOptionsMenuToast/MetaOptionsMenuToast'
import MapLevelCounter from '../MapLevelBadge/components/MapLevelCounter/MapLevelCounter'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'
import ToastDrawer from '../../atoms/ToastDrawer/ToastDrawer'
import { useIsMobile } from '../../../hooks/useIsMobile'

const PopupSelector = ({
  isIntro,
  isLoading,
  showToast,
  setShowToast,
  content,
  type,
  typePopupSelector,
  level,
  coin = 0
}) => {
  const isMobile = useIsMobile()

  const headerContent = (
    <div
      className={`meta-popup-selector__header-left__content ${
        type === typePopupSelector.PETS &&
        'meta-popup-selector__header-left__content-big'
      }`}
    >
      {(type === typePopupSelector.AVATAR && !isIntro) ||
      type === typePopupSelector.PETS ? (
        <>
          <div className="meta-popup-selector__header-left__content-level">
            <div className="meta-popup-selector__header-left__content-level-text">
              {translate('level')}
            </div>
            <MapLevelCounter level={level} />
          </div>
          {type === typePopupSelector.PETS && (
            <div className="meta-popup-selector__header-left__content-coins">
              <div className="meta-popup-selector__header-left__content-coins-text">
                {coin}
              </div>
              <div className="meta-popup-selector__header-left__content-coins-icon">
                <CoinSvg />
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  )

  return (
    <div
      className={`meta-popup-selector ${isIntro && isMobile ? 'intro' : ''} ${
        type === typePopupSelector.PETS ? 'pets' : ''
      }`}
    >
      {showToast && (
        <div className="meta-popup-selector__toast">
          {isMobile ? (
            <ToastDrawer
              handleClose={() => setShowToast(false)}
              text={
                type === typePopupSelector.PETS
                  ? translate('saved_pet_toast_message')
                  : translate('saved_avatar_toast_message')
              }
            />
          ) : (
            <MetaOptionsMenuToast
              text={
                type === typePopupSelector.PETS
                  ? translate('saved_pet_toast_message')
                  : translate('saved_avatar_toast_message')
              }
            />
          )}
        </div>
      )}

      <div
        className={`meta-popup-selector__header ${
          !isIntro && !isMobile
            ? ''
            : 'meta-popup-selector__header--no-background'
        }`}
      >
        <div className="meta-popup-selector__header-top">
          <div className="meta-popup-selector__header-left">
            {(!isMobile || type !== typePopupSelector.PETS) && headerContent}
          </div>

          <div className="meta-popup-selector__header-center">
            <div className="meta-popup-selector__title">
              {type === typePopupSelector.AVATAR
                ? translate('avatar_title')
                : translate('pets_title')}
            </div>
          </div>

          <div className="meta-popup-selector__header-right">
            <div
              className={
                isIntro
                  ? 'meta-popup-selector__header-right__content'
                  : 'meta-popup-selector__header-right__content-empty'
              }
            ></div>
          </div>
        </div>
      </div>

      {!isIntro && isMobile && (
        <div className="meta-popup-selector__decoration"></div>
      )}
      <div className="meta-popup-selector__content-container">
        <div className="meta-popup-selector__content">
          {isMobile && type === typePopupSelector.PETS && headerContent}
          {!isLoading && (
            <>
              <div className="meta-popup-selector__grid">{content}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupSelector
