import React from 'react'
import pompa from '../assets/pompa.png'
import './playerWaitingTeacherPractive.scss'

const PlayerWaitingTeacherPractice = ({
  player,
  defaultAvatarImage,
  playersWithParsedSettings,
  NUMBER_PLAYERS_FULL,
  windowSize
}) => {
  const getPlayerAvatar = () => {
    return (
      <div
        className={
          player.is_online
            ? 'background-avatar lobby-player-online'
            : 'background-avatar blocked'
        }
      >
        <img
          className={
            playersWithParsedSettings.length >= NUMBER_PLAYERS_FULL
              ? 'pompa-full'
              : 'pompa'
          }
          src={pompa}
        />
        <div
          className={
            playersWithParsedSettings.length >= NUMBER_PLAYERS_FULL
              ? 'container-avatar-full'
              : 'container-avatar'
          }
        >
          <img
            className={
              playersWithParsedSettings.length >= NUMBER_PLAYERS_FULL
                ? 'player-avatar-full'
                : 'player-avatar'
            }
            src={
              player.parsedSettings?.blueberry?.avatar?.single_image
                ? player.parsedSettings.blueberry.avatar.single_image
                : defaultAvatarImage
            }
            alt={player.name}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="player-waiting-teacher-practice player-waiting-teacher-practice_item-list">
      {getPlayerAvatar()}
      <div className="player-waiting-teacher-practice_item-name">
        {player.name + ' ' + player.lastname.charAt(0) + '.'}
      </div>
    </div>
  )
}

export default PlayerWaitingTeacherPractice
