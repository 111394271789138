import React from 'react'
import './dynamicTextContainer.scss'
import Title from '../Title/Title'
import { ReactComponent as AudioButton } from '../../../assets/img/metaberry_ui/sound_on.svg'

function DynamicTextContainer({
  titleText,
  level,
  message,
  batteryIcon,
  completeTitle,
  coins,
  audioFile,
  playSoundEffect,
  className
}) {
  const renderMessage = () => {
    if (!message) return null
    return (
      <div
        className="information-popup_message-text"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    )
  }

  return (
    <div className={`information-popup_message-wrapper-out ${className ?? ''}`}>
      <div className={`information-popup_message-wrapper-in `}>
        <div className="title-container-wrapper">
          <Title text={titleText} />
        </div>

        {level && renderMessage()}

        {batteryIcon && <Title text={completeTitle} />}

        {coins && renderMessage()}

        {audioFile && (
          <AudioButton
            className="information-popup_message-audio-button"
            onClick={() => playSoundEffect(audioFile, true)}
          />
        )}
      </div>
    </div>
  )
}

export default DynamicTextContainer
